import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faPlus,
  faArrowRight,
  faSave,
  faSearch,
  faFileText,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { QRCodeCanvas } from "qrcode.react";
import InputMask from "react-input-mask";

import { StaticDialog } from "react-st-modal";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link, json } from "react-router-dom";
import { create } from "@mui/material/styles/createTransitions";

class Carts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      new: false,
      trash: {},
      searchTerm: "",
      searchResults: [],
      showSearchResults: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };
  formatarDataBR(isoDate) {
    return new Date(isoDate).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    this.setState({ searchTerm }, () => {
      if (searchTerm.length > 0) {
        const searchResults = this.props.data.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchTerm)
          )
        );

        this.setState({
          searchResults,
          showSearchResults: true,
          currentPage: 0,
        });
      } else {
        this.setState({
          searchResults: [],
          showSearchResults: false,
          currentPage: 0,
        });
      }
    });
  };

  render() {
    const {
      currentPage,
      itemsPerPage,
      searchTerm,
      searchResults,
      showSearchResults,
    } = this.state;
    const data = showSearchResults ? searchResults : this.props.data || []; // Agora data vem das props

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dataPaginada = data.slice(startIndex, endIndex);

    return (
      <>
        <div className="py-12">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-3 w-100">
                    <Link
                      className="btn btn-danger d-flex align-items-center gap-2"
                      to={`/${
                        window.location.pathname.split("/")[1]
                      }/novo/cartorio`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar Cartório
                    </Link>

                    <div className="input-group w-50">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar:"
                        value={searchTerm}
                        onChange={this.handleSearch}
                      />
                      <button className="btn btn-primary">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  {data.length === 0 ? (
                    <>
                      {" "}
                      <div className="text-center">
                        {searchTerm && searchTerm.length > 0
                          ? "Nenhum Cartório encontrado"
                          : "Nenhum Cartório encontrado"}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3">
                        <strong>Lista de Cartórios</strong>
                      </div>
                      <table className="ntbl table table-sm table-bordered">
                        {window.location.pathname.split("/")[1] ===
                          "funarpen" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Responsável</th>
                                <th scope="col">Endereço</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>
                                    <td>{item.resp}</td>
                                    <td>{item.edrc}</td>
                                    <td>
                                      <div class="btn-group btn-group-sm">
                                        <button
                                          class="btn btn-info"
                                          onClick={() => {
                                            this.setState({ Edit: item });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjrj" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>

                                <th scope="col">Endereço</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>

                                    <td>{item.edrc}</td>
                                    <td>
                                      <div class="btn-group btn-group-sm">
                                        <button class="btn btn-info">
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjmg" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Responsável</th>
                                <th scope="col">Qualificação</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>
                                    <td>{item.resp}</td>
                                    <td>{item.Cma}</td>
                                    <td>
                                      <div class="btn-group btn-group-sm">
                                        <button
                                          class="btn btn-info"
                                          onClick={() => {
                                            console.log(item);
                                            this.setState({
                                              editar: item,
                                              Medit: true,
                                            });
                                            return;
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjsp" && (
                          <>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>Responsável</th>
                                <th>Endereço</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{startIndex + index + 1}</td>
                                  <td>{item.nome}</td>
                                  <td>{item.resp}</td>
                                  <td>{item.edrc}</td>
                                  <td>
                                    <div className="btn-group btn-group-sm">
                                      <button class="btn btn-info">
                                        <FontAwesomeIcon
                                          color="#fff"
                                          icon={faPen}
                                        />
                                      </button>
                                      <button>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjgo" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>

                                <th scope="col">Endereço</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>

                                    <td>{item.edrc}</td>
                                    <td>
                                      <div class="btn-group btn-group-sm">
                                        <button class="btn btn-info">
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjrs" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Endereço</th>
                                <th scope="col">Serventia</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>
                                    <td>{item.edrc}</td>
                                    <td>{item.codeser}</td>
                                    <td>
                                      <div class="btn-group btn-group-sm">
                                        <button class="btn btn-info">
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjsc" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>

                                <th scope="col">Endereço</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>

                                    <td>{item.edrc}</td>
                                    <td>
                                      <div class=" btn-group-sm">
                                        <button
                                          class="btn btn-primary"
                                          onClick={() => {
                                            console.log(item);
                                            this.setState({
                                              editCartorio: item,
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjes" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Responsável</th>
                                <th scope="col">Endereço</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>
                                    <td>{item.resp}</td>
                                    <td>{item.edrc}</td>
                                    <td>
                                      <div class="btn-group btn-group-sm">
                                        <button class="btn btn-info">
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faPen}
                                          />
                                        </button>
                                        <button class="btn btn-danger">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                      </table>
                      {data.length > 0 && (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-secondary mx-1"
                            disabled={currentPage === 0}
                            onClick={() =>
                              this.handlePageChange(currentPage - 1)
                            }
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Anterior
                          </button>

                          {[...Array(totalPages).keys()].map((page) => {
                            const isFirstPages = page < 3;
                            const isLastPages = page >= totalPages - 3;
                            const isAroundCurrent =
                              Math.abs(currentPage - page) <= 2;

                            if (
                              isFirstPages ||
                              isLastPages ||
                              isAroundCurrent
                            ) {
                              return (
                                <button
                                  key={page}
                                  className={`btn mx-1 ${
                                    currentPage === page
                                      ? "btn-primary"
                                      : "btn-outline-secondary"
                                  }`}
                                  onClick={() => this.handlePageChange(page)}
                                >
                                  {page + 1}
                                </button>
                              );
                            } else if (
                              (page === 3 && currentPage > 5) ||
                              (page === totalPages - 4 &&
                                currentPage < totalPages - 5)
                            ) {
                              return (
                                <span key={page} className="mx-2">
                                  ...
                                </span>
                              );
                            }

                            return null;
                          })}

                          <button
                            className="btn btn-secondary mx-1"
                            disabled={currentPage === totalPages - 1}
                            onClick={() =>
                              this.handlePageChange(currentPage + 1)
                            }
                          >
                            Próximo <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Carts;
