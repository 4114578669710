import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
  faSave,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import { StaticDialog } from "react-st-modal";
import Folders from "../../Comps/Folders";
import Certs from "../../Certs";
import Carts from "../../Comps/Carts";
import CertCRC from "../../Comps/CertCRC";

class TjMg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      currentPage: 1,
      itemsPerPage: 40,
      totalPages: 0,
      editar: [],
      Medit: false,
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptQuali = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "Minas Gerais - Consulta";
    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false, totalPages: data.totalPage });
        });
      }
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchItems(page);
    });
  };
  fetchItems(page) {
    fetch(
      `${apiUrl}/${
        window.location.pathname.split("/")[1]
      }/certificados/${page}/${this.state.itemsPerPage}`
    )
      .then((response) => response.json())
      .then((resp) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            certs: resp,
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  }
  Edit = (e) => {
    const item = e.target;
    this.setState((prevState) => ({
      editar: {
        ...prevState.editar,
        [item.name]: item.value,
      },
    }));
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }

    const { totalPages, currentPage } = this.state;
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return (
      <>
        <header class=" shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjMg{" "}
            </h2>
          </div>
        </header>
        <main>
          <Carts data={this.state.data.cartorio} />

          <Folders />

          <Certs data={this.state.data.certs} />
          <CertCRC data={this.state.data.crc} />
        </main>
        <div id="modal-root"></div>
        <ToastContainer />
        <StaticDialog
          isOpen={this.state.Medit}
          title="Editar"
          onAfterClose={(result) => {
            this.setState({ Medit: false, editar: [] });
          }}
        >
          <div style={{ padding: 15 }}>
            <div class="form-group mt-2">
              <label>Nome do Ofício:</label>
              <input
                type="text"
                value={this.state.editar.nome || ""}
                name="nome"
                class="form-control form-control-sm"
                onChange={this.Edit}
              />
            </div>

            <div class="form-group mt-2">
              <label>Responsável:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                value={this.state.editar.resp || ""}
                name="resp"
                onChange={this.Edit}
              />
            </div>

            <div class="form-group mt-2">
              <label>Qualificação:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                value={this.state.editar.Cma || ""}
                name="Cma"
                onChange={this.Edit}
              />
            </div>

            <div class="form-group mt-2">
              <label>Cns:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                value={this.state.editar.Cns || ""}
                name="Cns"
                onChange={this.Edit}
              />
            </div>
          </div>
          <hr />
          <div className="modal-button">
            <button
              className="btn btn-primary float-right ml-2"
              onClick={(e) => {
                e.target.classList.add("load");
                var item = this.state.editar;
                console.log(item);
                fetch(
                  `${apiUrl}${window.location.pathname}/atualizar-cartorio/${item.id}`,
                  {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc: item.nome,
                      Resp: item.resp,
                      Cma: item.Cma,
                      Cns: item.Cns,
                    }),
                  }
                ).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status == 200) {
                    response.json().then((data) => {
                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: prevState.data.cartorio.map((i) =>
                            i.id === item.id
                              ? {
                                  ...i,
                                  nome: item.nome,
                                  resp: item.resp,
                                  Cma: item.Cma,
                                  cns: item.Cns,
                                }
                              : i
                          ),
                        },
                      }));

                      this.setState({ Medit: false, editar: [] });
                      toast.success("Suceeso!");
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default TjMg;
