import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../config";
import { TextField } from "@mui/material";
class CreateCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      docEmt: "CERTIDÃO",
      folder: false,
      dados: {},
    };
  }

  componentDidMount() {}
  handleCepBlur = (e) => {
    var cep = e.target.value.replace(/\D/g, "");
    if (cep.length > 0) {
      fetch(`${apiUrl}/cep/${cep}`).then((response) => {
        if (response.status == 404) {
          toast.error("Cep inválido!");
          return;
        }
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              dados: {
                ...this.state.dados,
                cep,
                Edrc: data.logradouro,
                bairro: data.bairro,
                municipio: data.localidade,
              },
            });
          });
        }
      });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };

  render() {
    var sigla = window.location.pathname.split("/")[1];
    return (
      <>
        <header class="">
          <div class="d-flex align-items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2
              class="font-semibold cursor-pointer text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Adicionar Cartório
            </h2>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3>Dados:</h3>
                  </div>
                  <div class="card-body">
                    {sigla == "funarpen" && (
                      <>
                        {" "}
                        <div>
                          <div className="form-group mt-2">
                            <label>Nome do Ofício:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="nomeOfc"
                              value={this.state.dados.nomeOfc}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Responsável:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Resp"
                              value={this.state.dados.Resp}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Endereço:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Edrc"
                              value={this.state.dados.Edrc}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Cidade/UF:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Cidade"
                              value={this.state.dados.Cidade}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Telefone:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Telefone"
                              value={this.state.dados.Telefone}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Cns:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Cns"
                              value={this.state.dados.Cns}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {sigla == "tjsp" && (
                      <>
                        <div className="form-group mt-2">
                          <label>Nome do Ofício:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="nomeOfc"
                            value={this.state.dados.nomeOfc}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Responsável:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Resp"
                            value={this.state.dados.Resp}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Endereço:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Edrc"
                            value={this.state.dados.Edrc}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Cidade/UF:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Cidade"
                            value={this.state.dados.Cidade}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Telefone:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Telefone"
                            value={this.state.dados.Telefone}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Cns:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Cns"
                            value={this.state.dados.Cns}
                            onChange={this.handleChange}
                          />
                        </div>
                      </>
                    )}

                    {sigla == "tjrj" && (
                      <>
                        <div className="form-group mt-2">
                          <label>Código da serventia:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Cds"
                            value={this.state.dados.Cds}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Nome do Ofício:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="nomeOfc"
                            value={this.state.dados.nomeOfc}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Cep:</label>
                          <InputMask
                            mask="99.999-999"
                            type="text"
                            className="form-control form-control-sm"
                            name="cep"
                            value={this.state.dados.cep}
                            onBlur={this.handleCepBlur}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Endereço:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            value={this.state.dados.Edrc}
                            name="Edrc"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Bairro:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            value={this.state.dados.bairro}
                            name="bairro"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Município:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            value={this.state.dados.municipio}
                            name="municipio"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Telefone:</label>
                          <InputMask
                            mask="(99) 9999-9999"
                            type="text"
                            className="form-control form-control-sm"
                            name="Telefone"
                            value={this.state.dados.Telefone}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Comarca:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Cma"
                            value={this.state.dados.Cma}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Cns:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Cns"
                            value={this.state.dados.Cns}
                            onChange={this.handleChange}
                          />
                        </div>
                      </>
                    )}

                    {sigla == "tjmg" && (
                      <>
                        <div>
                          <div className="form-group mt-2">
                            <label>Nome do Ofício:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="nomeOfc"
                              value={this.state.dados.nomeOfc}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Responsável:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Resp"
                              value={this.state.dados.Resp}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Qualificação:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Quali"
                              value={this.state.dados.Quali}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group mt-2">
                            <label>Cns:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Cns"
                              value={this.state.dados.Cns}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {sigla == "tjgo" && (
                      <>
                        <div className="card-body">
                          <div className="form-group mt-2">
                            <label>Código de cartório:</label>
                            <input
                              type="text"
                              name="code"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Nome do Ofício:</label>
                            <input
                              type="text"
                              name="nomeOfc"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Código da serventia:</label>
                            <InputMask
                              type="text"
                              name="codeser"
                              value={this.state.dados.codeser}
                              mask="9999"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Endereço:</label>
                            <input
                              type="text"
                              name="Edrc"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Cidade/UF:</label>
                            <input
                              type="text"
                              name="Cidade"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Telefone:</label>
                            <InputMask
                              mask="(99) 9999-9999"
                              type="text"
                              name="Telefone"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>CNS:</label>
                            <input
                              type="text"
                              name="Cns"
                              className="form-control form-control-sm"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {sigla == "tjrs" && (
                      <>
                        <div className="form-group mt-2">
                          <label>Nome do Ofício:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="nome"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="form-group mt-2">
                          <label>
                            Endereço: (Sem colocar <strong>Bairro</strong>)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="edrc"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="form-group mt-2">
                          <label>Cidade:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="cidade"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="form-group mt-2">
                          <label>CNS:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Cns"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="form-group mt-2">
                          <label>Serventia:</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="serventia"
                            onChange={this.handleChange}
                          />
                        </div>
                      </>
                    )}
                    {sigla == "tjsc" && (
                      <>
                        <div className="container">
                          <div className="mt-3 mb-3">
                            <TextField
                              label="Nome do Ofício"
                              value={this.state.dados.nomeOfc}
                              name="nomeOfc"
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="mt-3 mb-3">
                            <TextField
                              label="Oficial"
                              name="ofc"
                              value={this.state.dados.ofc}
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="mt-3 mb-3">
                            <TextField
                              label="Responsável Pela Assinatura"
                              name="rpa"
                              value={this.state.dados.rpa}
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="mt-3 mb-3">
                            <TextField
                              label="Cargo do Responsável"
                              name="cdr"
                              value={this.state.dados.cdr}
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="mb-3">
                            <TextField
                              label="Endereço"
                              value={this.state.dados.Edrc}
                              name="Edrc"
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="mb-3">
                            <TextField
                              label="Bairro"
                              value={this.state.dados.bairro}
                              name="bairro"
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <TextField
                                label="Cidade"
                                value={this.state.dados.cidade}
                                name="cidade"
                                onChange={this.handleChange}
                                InputProps={{
                                  style: { height: 45, padding: "0 5px" },
                                }}
                                fullWidth
                              />
                            </div>

                            <div className="col-md-6">
                              <TextField
                                label="Estado"
                                value="/ SC"
                                InputProps={{
                                  style: { height: 45, padding: "0 5px" },
                                }}
                                disabled
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <TextField
                                label="ISS"
                                value={this.state.dados.frj}
                                name="frj"
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    dados: {
                                      ...prevState.dados,
                                      frj: this.formatarMoeda(e.target.value),
                                    },
                                  }));
                                }}
                                InputProps={{
                                  style: { height: 45, padding: "0 5px" },
                                }}
                                fullWidth
                              />
                            </div>
                            <div className="col-md-6">
                              <TextField
                                label="CNS"
                                value={this.state.dados.Cns}
                                name="Cns"
                                onChange={this.handleChange}
                                InputProps={{
                                  style: { height: 45, padding: "0 5px" },
                                }}
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <TextField
                              label="Telefones"
                              value={this.state.dados.Telefone}
                              name="Telefone"
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>

                          <div className="mb-3">
                            <TextField
                              label="E-mail"
                              value={this.state.dados.email}
                              name="email"
                              onChange={this.handleChange}
                              InputProps={{
                                style: { height: 45, padding: "0 5px" },
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {sigla == "tjes" && (
                      <>
                        <div className="">
                          <div className="form-group mt-2">
                            <label>Nome do Ofício:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="nomeOfc"
                              value={this.state.dados.nomeOfc}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Responsável:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Resp"
                              value={this.state.dados.Resp}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Endereço:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Edrc"
                              value={this.state.dados.Edrc}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Cidade/UF:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Cidade"
                              value={this.state.dados.Cidade}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Telefone:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Telefone"
                              value={this.state.dados.Telefone}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group mt-2">
                            <label>Cns:</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="Cns"
                              value={this.state.dados.Cns}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="card-footer text-right">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.target.classList.add("load");
                        fetch(`${apiUrl}/${sigla}/adicionar-cartorio/`, {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization:
                              "Bearer " + Cookies.get("auth_token"),
                          },
                          body: JSON.stringify(this.state.dados),
                        }).then((response) => {
                          e.target.classList.remove("load");
                          toast.success("Cartório criado com sucesso!");
                          this.props.navigate(`/${sigla}`);
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default CreateCart;
