import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import InputMask from "react-input-mask";
import { Confirm, useDialog } from "react-st-modal";
import Folders from "../../Comps/Folders";
import Certs from "../../Certs";
import Carts from "../../Comps/Carts";
import CertCRC from "../../Comps/CertCRC";

class TjGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      currentPage: 1,
      itemsPerPage: 5,
      totalPages: 0,
    };
    this.iptCode = React.createRef();
    this.iptNome = React.createRef();
    this.iptCodeSer = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
    this.dialog = useDialog;
  }

  componentDidMount() {
    document.title = "Goías - Consulta";

    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, totalPages: data.totalPage, Loader: false });
        });
      }
    });
  }

  fetchItems(page) {
    fetch(
      `${apiUrl}/${
        window.location.pathname.split("/")[1]
      }/certificados/items?page=${page}&limit=${this.state.itemsPerPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ items: data });
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchItems(page);
    });
  };

  render() {
    const { totalPages, currentPage } = this.state;
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class=" ">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjGo{" "}
            </h2>
          </div>
        </header>
        <main>
          <Carts data={this.state.data.cartorio} />

          <Folders />
          <Certs data={this.state.data.certs} />
          <CertCRC data={this.state.data.crc} />
        </main>
        <div id="modal-root"></div>
        <ToastContainer />
      </>
    );
  }
}

export default TjGo;
