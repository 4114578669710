import React, { Component } from "react";
import selo from "../../../src/images/selo.jpg";
import rgc from "../../../src/images/registro-civil-logo.svg";
import qr from "../../../src/images/qr.png";
import "./s.css";
import { apiUrl } from "../../config";

class StampView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loader: true,
    };
  }
  componentDidMount() {
    document.title = "Log in - Consulta";
    fetch(
      `${apiUrl}/get/nacional/stamp/` + window.location.pathname.split("/")[2],
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, loader: false });
        });
      }
    });
  }

  render() {
    if (this.state.loader) {
      return <>Carregando...</>;
    }
    var data = this.state.data;
    return (
      <div className="pdf-container">
        <div className="pdf-content">
          <img src={selo} alt="Imagem de fundo" className="pdf-image" />
          <div className="overlay-text">
            <div
              style={{
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              {data.tipo_ato}
            </div>
            <div
              className="d-flex flex-column align-items-center"
              style={{
                marginTop: 20,
              }}
            >
              <span style={{ marginBottom: -8, fontSize: 8 }}>NOME</span>
              <span style={{ fontWeight: "bold", fontSize: 20 }}>
                {data.titular}
              </span>
            </div>
            <div
              style={{
                position: "relative",
                marginTop: 3,
                border: "1px solid black",
                width: "75%",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  fontSize: 10,
                  marginTop: -12.5,
                  background: "white",
                  transform: "translate(-50%, -50%)",
                }}
              >
                Número do CPF
              </div>
              <span
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {data.cpf}
              </span>
            </div>
            <div style={{ marginTop: 5, marginBottom: -8 }}>
              <span style={{ marginBottom: -8, fontSize: 8 }}>MATRICULA</span>
            </div>
            <span style={{ fontWeight: "bold" }}>
              {data.cns} 01 55 {data.data_registro.split("-")[0].split("/")[2]}{" "}
              {data.tlr} {data.num_livro} {data.pag_inicial} {data.num_assento}{" "}
              {data.verificador}
            </span>
          </div>
          <div
            style={{
              position: "relative",
              marginTop: 3,
              border: "1px solid black",
              width: "75%",
              textAlign: "justify",
              fontSize: 11,
            }}
          >
            <div style={{ padding: 10 }}>{data.obs_cert}</div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
              width: "70%",
              fontSize: 11,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div>Oficial de Registro Civil das Pessoas Naturais</div>
              <div>{data.nome_cartorio}</div>
              <div style={{ marginTop: 10 }}>{data.ofc} - Oficial</div>
              <div style={{ marginTop: 10 }}>{data.edrc} CEP: 95020370 -</div>
              <div>Fone: </div>
              <div>e-mail: </div>
            </div>
            <div>
              <div>O Conteúdo da certidão é verdadeiro. Dou fé.</div>
              <div style={{ textAlign: "center" }}>
                <div style={{ marginTop: 18 }}>
                  Caxias do Sul - RS, 16/01/2025
                </div>
                <div>
                  <strong>Selo digital:</strong> {data.selo_e}
                </div>
                <div>Valor cobrado por esta certidão: </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 1000,
              width: "75%",
            }}
          >
            <div
              style={{
                display: "flex",

                fontSize: 11,
                marginBottom: 10,
                marginLeft: 70,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: -20,
                  flexShrink: 0,
                }}
              >
                <img
                  src={rgc}
                  alt="Imagem de fundo"
                  style={{
                    width: 80,
                  }}
                />
                <img
                  src={qr}
                  style={{ width: 45, height: 45, marginLeft: 10 }}
                />
              </div>

              <div
                style={{
                  flexGrow: 1,
                  marginLeft: 5,
                  fontSize: 9,
                  lineHeight: 1,
                }}
              >
                <div>
                  Assinado eletronicamente por: $nome - $data , Nos termos do
                  artigo 19 da Lei n° 6.015/73, e do artigo 228-F do Código
                  Nacional de Normas da Corregedoria Nacional de Justiça do
                  Conselho Nacional
                </div>
                <div>de Justiça - Foro Extrajudicial (CNN/CN/CNJ-Extra)</div>
                <div>CNS: {data.cns} - Oficial - $cidade</div>
                <div>
                  <span>
                    {" "}
                    Validação: https://certidao.registrocivil.org.br/validar
                  </span>
                  <span style={{ marginLeft: 50 }}>
                    Código validador: {data.selo_n}
                  </span>
                </div>
                <div>
                  <a href="">Clique aqui</a> para validar a certidão
                </div>
              </div>
            </div>

            <div
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                fontSize: 9,
                textAlign: "justify",
              }}
            >
              O QR Code do selo de fiscalização dos Tribunais de Justiça
              Estaduais estará disponível na tabela de validação desta certidão
              no endereço mencionado abaixo quando não estiver presente na
              própria certidão. Esta certidão poderá ser materializada em até 30
              dias da data de sua emissão em qualquer Ofício de Registro Civil
              de Pessoas Naturais do Brasil
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StampView;
