import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faLink } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class QrCodeCard extends Component {
  render() {
    const { qr, link, token } = this.props.data;

    return (
      <div className="card mb-2">
        <div class="card-header d-flex  justify-content-between">
          <h3>Selo Estadual:</h3>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex  align-items-center">
              <div className="d-flex flex-column">
                <img src={qr} style={{ width: "164px", height: "164px" }} />
              </div>

              <div className="d-flex  align-items-center">
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn  btn-primary"
                  onClick={() => {
                    const img = document.createElement("img");
                    img.src = qr;
                    document.body.appendChild(img);
                    const range = document.createRange();
                    range.selectNode(img);
                    const sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                    document.execCommand("copy");
                    document.body.removeChild(img);

                    toast.success(
                      "Imagem copiada para a área de transferência!"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} /> Copiar
                </button>
                <a href={link} target="_blank" style={{ marginLeft: "10px" }}>
                  <button class="btn btn-xs btn-primary">
                    <FontAwesomeIcon color="#fff" icon={faLink} /> Acessar
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            className="mt-2 text-center mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const textArea = document.createElement("textarea"); // Cria um elemento temporário
              textArea.value = window.location.pathname.split("/").pop(); // Define o valor como o texto da variável
              document.body.appendChild(textArea); // Adiciona ao DOM
              textArea.select(); // Seleciona o texto
              document.execCommand("copy"); // Copia
              document.body.removeChild(textArea); // Remove o elemento temporário
              toast.success("Token copiado com sucesso!");
            }}
          >
            {" "}
            {window.location.pathname.split("/").pop()}
          </div>
        </div>
      </div>
    );
  }
}

export default QrCodeCard;
