import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faPlus,
  faArrowRight,
  faSave,
  faSearch,
  faFileText,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { QRCodeCanvas } from "qrcode.react";
import InputMask from "react-input-mask";

import { StaticDialog } from "react-st-modal";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link, json } from "react-router-dom";
import { create } from "@mui/material/styles/createTransitions";

class CertCRC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      new: false,
      trash: {},
      searchTerm: "",
      searchResults: [],
      showSearchResults: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };
  formatarDataBR(isoDate) {
    return new Date(isoDate).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    this.setState({ searchTerm }, () => {
      if (searchTerm.length > 0) {
        const searchResults = this.props.data.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchTerm)
          )
        );

        this.setState({
          searchResults,
          showSearchResults: true,
          currentPage: 0,
        });
      } else {
        this.setState({
          searchResults: [],
          showSearchResults: false,
          currentPage: 0,
        });
      }
    });
  };

  render() {
    const {
      currentPage,
      itemsPerPage,
      searchTerm,
      searchResults,
      showSearchResults,
    } = this.state;
    const data = showSearchResults ? searchResults : this.props.data || []; // Agora data vem das props

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dataPaginada = data.slice(startIndex, endIndex);

    return (
      <>
        <div className="py-12">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <strong>Selos Gerados Para Outros Estados</strong>
                    <div className="d-flex gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar:"
                        value={searchTerm}
                        onChange={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {data.length === 0 ? (
                    <>
                      {" "}
                      <div className="text-center">
                        {searchTerm && searchTerm.length > 0
                          ? "Nenhum selo encontrado"
                          : "Nenhum selo encontrado"}
                      </div>
                    </>
                  ) : (
                    <>
                      <table className="ntbl table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Tipo de documento</th>

                            <th>Selo Validador CRC</th>
                            <th>Estado Emissor </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataPaginada.map((item, index) => (
                            <>
                              <tr>
                                <td>{item.docEmt}</td>

                                <td>{item.token}</td>
                                <td>{item.ref}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      {data.length > 0 && (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-secondary mx-1"
                            disabled={currentPage === 0}
                            onClick={() =>
                              this.handlePageChange(currentPage - 1)
                            }
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Anterior
                          </button>

                          {[...Array(totalPages).keys()].map((page) => {
                            const isFirstPages = page < 3;
                            const isLastPages = page >= totalPages - 3;
                            const isAroundCurrent =
                              Math.abs(currentPage - page) <= 2;

                            if (
                              isFirstPages ||
                              isLastPages ||
                              isAroundCurrent
                            ) {
                              return (
                                <button
                                  key={page}
                                  className={`btn mx-1 ${
                                    currentPage === page
                                      ? "btn-primary"
                                      : "btn-outline-secondary"
                                  }`}
                                  onClick={() => this.handlePageChange(page)}
                                >
                                  {page + 1}
                                </button>
                              );
                            } else if (
                              (page === 3 && currentPage > 5) ||
                              (page === totalPages - 4 &&
                                currentPage < totalPages - 5)
                            ) {
                              return (
                                <span key={page} className="mx-2">
                                  ...
                                </span>
                              );
                            }

                            return null;
                          })}

                          <button
                            className="btn btn-secondary mx-1"
                            disabled={currentPage === totalPages - 1}
                            onClick={() =>
                              this.handlePageChange(currentPage + 1)
                            }
                          >
                            Próximo <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CertCRC;
