import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
  faArrowLeft,
  faArrowRight,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import { TextField } from "@mui/material";
import { StaticDialog } from "react-st-modal";
import Folders from "../../Comps/Folders";
import Certs from "../../Certs";
import Carts from "../../Comps/Carts";
import CertCRC from "../../Comps/CertCRC";

class TjSp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      currentPage: 0,
      itemsPerPage: 10,

      currentPagec: 0,
      itemsPerPagec: 10,

      Edit: {},
      dados: {},
      addc: false,
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "São Paulo - Consulta";
    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageChangec = (page) => {
    this.setState({ currentPagec: page });
  };

  handleEditChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      Edit: {
        ...prevState.Edit, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    const { cartorio } = this.state.data;
    const { currentPage, itemsPerPage } = this.state;

    const totalPages = Math.ceil(cartorio.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const cartorioPaginado = cartorio.slice(startIndex, endIndex);

    const { certs } = this.state.data;
    const { currentPagec, itemsPerPagec } = this.state;

    const totalPagesc = Math.ceil(certs.length / itemsPerPagec);
    const startIndexc = currentPagec * itemsPerPage;
    const endIndexc = startIndexc + itemsPerPagec;
    const certsPaginado = certs.slice(startIndexc, endIndexc);

    return (
      <>
        <header class="">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjSp{" "}
            </h2>
          </div>
        </header>
        <main>
          <Carts data={this.state.data.cartorio} />

          <Folders />

          <Certs data={this.state.data.certs} />

          <CertCRC data={this.state.data.crc} />
        </main>
        <div id="modal-root"></div>
        <StaticDialog
          isOpen={this.state.addC}
          title="Adicionar Cartório"
          onAfterClose={(result) => {
            this.setState({ addC: false, dados: {} });
          }}
        >
          <div className="container">
            <form
              id="cadastroForm"
              onSubmit={(e) => {
                var dados = this.state.dados;
                e.preventDefault();
                console.log(dados);
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label="Nome do Oficio"
                    name="nomeOfc"
                    onChange={this.handleChange}
                    value={this.state.dados.nomeOfc || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Resposável"
                    name="resp"
                    onChange={this.handleChange}
                    value={this.state.dados.resp || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Resposável Pela Assinatura"
                    name="resp_ass"
                    onChange={this.handleChange}
                    value={this.state.dados.resp_ass || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Cargo do Resposável"
                    name="c_resp"
                    onChange={this.handleChange}
                    value={this.state.dados.c_resp || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Endereço"
                    name="edrc"
                    value={this.state.dados.edrc || ""}
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Cidade/UF"
                    name="cidade"
                    onChange={this.handleChange}
                    value={this.state.dados.cidade || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Telefone"
                    name="telefone"
                    onChange={this.handleChange}
                    value={this.state.dados.telefone || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                  <TextField
                    label="CNS"
                    name="cns"
                    onChange={this.handleChange}
                    value={this.state.dados.cns || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(
                  `${apiUrl}${window.location.pathname}/adicionar-cartorio/`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc: this.state.dados.nomeOfc,
                      Resp: this.state.dados.resp,
                      Edrc: this.state.dados.edrc,
                      Cidade: this.state.dados.cidade,
                      Telefone: this.state.dados.telefone,
                      resp_ass: this.state.dados.resp_ass,
                      c_resp: this.state.dados.c_resp,
                      Cns: this.state.dados.cns,
                    }),
                  }
                ).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status == 200) {
                    response.json().then((data) => {
                      toast.success("Cartório adicionado com sucesso!");

                      const newItem = {
                        id: data.id,
                        nome: this.state.dados.nomeOfc,
                        resp: this.state.dados.resp,
                        edrc: this.state.dados.edrc,
                        cidade: this.state.dados.cidade,
                        telefone: this.state.dados.telefone,
                        resp_ass: this.state.dados.resp_ass,
                        c_resp: this.state.dados.c_resp,
                        Cns: this.state.dados.cns,
                      };

                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: [newItem, ...prevState.data.cartorio],
                        },
                        dados: {},
                        addC: false,
                      }));
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
        <StaticDialog
          isOpen={Object.keys(this.state.Edit).length > 0}
          title="Editar Cartório"
          onAfterClose={(result) => {
            this.setState({ Edit: {} });
          }}
        >
          <div className="container">
            <form
              id="cadastroForm"
              onSubmit={(e) => {
                var dados = this.state.dados;
                e.preventDefault();
                console.log(dados);
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label="Nome do Oficio"
                    name="nome"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.nome || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Resposável"
                    name="resp"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.resp || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Resposável Pela Assinatura"
                    name="resp_ass"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.resp_ass || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Cargo do Resposável"
                    name="c_resp"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.c_resp || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Endereço"
                    name="edrc"
                    value={this.state.Edit.edrc || ""}
                    onChange={this.handleEditChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Cidade/UF"
                    name="cidade"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.cidade || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />

                  <TextField
                    label="Telefone"
                    name="telefone"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.telefone || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                  <TextField
                    label="CNS"
                    name="cns"
                    onChange={this.handleEditChange}
                    value={this.state.Edit.cns || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");
                var Edit = this.state.Edit;
                fetch(
                  `${apiUrl}${window.location.pathname}/atualizar-cartorio/${Edit.id}`,
                  {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc: Edit.nome,
                      Resp: Edit.resp,
                      resp_ass: Edit.resp_ass,
                      c_resp: Edit.c_resp,
                      Edrc: Edit.edrc,
                      Cidade: Edit.cidade,
                      Telefone: Edit.telefone,
                      Cns: Edit.cns,
                    }),
                  }
                ).then((response) => {
                  if (response.status == 200) {
                    e.target.classList.remove("load");
                    response.json().then((data) => {
                      toast.success("Atualizado com sucesso!");

                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: prevState.data.cartorio.map((i) =>
                            i.id === Edit.id
                              ? {
                                  ...i,
                                  nome: Edit.nome,
                                  resp: Edit.resp,
                                  resp_ass: Edit.resp_ass,
                                  c_resp: Edit.c_resp,
                                  edrc: Edit.edrc,
                                  cidade: Edit.cidade,
                                  telefone: Edit.telefone,
                                  cns: Edit.cns,
                                }
                              : i
                          ),
                        },
                        Edit: {},
                      }));
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Atualziar
            </button>
          </div>
        </StaticDialog>
        <ToastContainer />
      </>
    );
  }
}

export default TjSp;
