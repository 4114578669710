import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faPlus,
  faArrowRight,
  faSave,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { QRCodeCanvas } from "qrcode.react";
import InputMask from "react-input-mask";
import { apiUrl } from "../../config";
import { StaticDialog } from "react-st-modal";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link, json } from "react-router-dom";
import { create } from "@mui/material/styles/createTransitions";

class Folders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      currentPage: 0,
      itemsPerPage: 5,
      new: false,
      trash: {},
      dados: {},
      searchTerm: "",
      searchResults: [],
      showSearchResults: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };
  formatarDataBR(isoDate) {
    return new Date(isoDate).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  componentDidMount() {
    this.fetchFolders();
  }

  fetchFolders = () => {
    fetch(apiUrl + `/pastas/${window.location.pathname.split("/")[1]}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            this.setState({
              data,
              Loader: false,
              searchResults: data.cartorio || [],
            });
          });
        } else {
          this.setState({ Loader: false });
          // Handle error, maybe show a toast
        }
      })
      .catch((error) => {
        console.error("Error fetching folders:", error);
        this.setState({ Loader: false });
        // Handle network error
      });
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    this.setState({ searchTerm }, () => {
      if (searchTerm.length > 0) {
        const searchResults = this.state.data.cartorio.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchTerm)
          )
        );

        this.setState({
          searchResults,
          showSearchResults: true,
          currentPage: 0,
        });
      } else {
        this.setState({
          searchResults: [],
          showSearchResults: false,
          currentPage: 0,
        });
      }
    });
  };

  render() {
    if (this.state.Loader) {
      return <>Carregando....</>;
    }

    const {
      data,
      currentPage,
      itemsPerPage,
      searchTerm,
      searchResults,
      showSearchResults,
    } = this.state;
    const cartorio = showSearchResults
      ? searchResults
      : (data && data.cartorio) || [];

    const totalPages = Math.ceil(cartorio.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const cartorioPaginado = cartorio.slice(startIndex, endIndex);

    return (
      <>
        <div className="">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-3 w-100">
                    <button
                      className="btn btn-danger d-flex align-items-center gap-2"
                      onClick={() => this.setState({ new: true })}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar Pasta
                    </button>

                    <div className="input-group w-50">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar:"
                        value={searchTerm}
                        onChange={this.handleSearch}
                      />
                      <button type="button" className="btn btn-primary">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  {this.state.Loader === true ? (
                    <>
                      {" "}
                      <div className="text-center">Carregando...</div>
                    </>
                  ) : (
                    <>
                      {cartorio.length === 0 ? (
                        <>
                          {" "}
                          <div className="text-center">
                            {searchTerm && searchTerm.length > 0
                              ? "Nenhuma pasta encontrada."
                              : "Nenhuma Pasta Encontrada"}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            <strong>Lista de Pastas</strong>
                          </div>
                          <table className="ntbl table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">REQUERENTE</th>
                                <th scope="col">FAMÍLIA</th>
                                <th scope="col">DATA</th>
                                <th scope="col">DOCUMENTOS</th>
                                <th scope="col">AÇÕES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartorioPaginado.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{item.req}</td>
                                  <td>{item.fam}</td>
                                  <td>
                                    {this.formatarDataBR(item.created_at)}
                                  </td>
                                  <td>{item.total_itens}</td>
                                  <td>
                                    <Link
                                      to={`/${
                                        window.location.pathname.split("/")[1]
                                      }/pasta/${item.id}`}
                                      className="btn btn-primary"
                                    >
                                      <FontAwesomeIcon icon={faArrowRight} />{" "}
                                      Acessar
                                    </Link>

                                    <button
                                      className="btn btn-danger"
                                      style={{ marginLeft: "5px" }}
                                      onClick={() => {
                                        this.setState({ trash: item });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} /> Remover
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {cartorio.length > 0 && (
                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-secondary mx-1"
                                disabled={currentPage === 0}
                                onClick={() =>
                                  this.handlePageChange(currentPage - 1)
                                }
                              >
                                <FontAwesomeIcon icon={faArrowLeft} /> Anterior
                              </button>

                              {[...Array(totalPages).keys()].map((page) => (
                                <button
                                  key={page}
                                  className={`btn mx-1 ${
                                    currentPage === page
                                      ? "btn-primary"
                                      : "btn-outline-secondary"
                                  }`}
                                  onClick={() => this.handlePageChange(page)}
                                >
                                  {page + 1}
                                </button>
                              ))}

                              <button
                                className="btn btn-secondary mx-1"
                                disabled={currentPage === totalPages - 1}
                                onClick={() =>
                                  this.handlePageChange(currentPage + 1)
                                }
                              >
                                Próximo <FontAwesomeIcon icon={faArrowRight} />
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div className="modal-body" style={{ margin: "10px" }}>
            <div className="text-center">
              {" "}
              Tem certeza que deseja remover essa pasta?
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");

                fetch(`${apiUrl}/remover-pasta/${this.state.trash.id}`, {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                  .then((response) => {
                    if (response.status === 200) {
                      var id = this.state.trash.id;
                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data, // Mantém o restante dos dados inalterados
                          cartorio: prevState.data.cartorio
                            ? prevState.data.cartorio.filter(
                                (item) => item.id !== id
                              )
                            : [],
                        },
                        trash: {},
                      }));
                      toast.success("Pasta removida com sucesso!");
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  })
                  .finally(() => {
                    e.target.classList.remove("load");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.new}
          title="Adicionar Pasta"
          onAfterClose={(result) => {
            this.setState({ new: false, dados: {} });
          }}
        >
          <div className="modal-body" style={{ margin: "10px" }}>
            <TextField
              label="Requerente"
              name="req"
              onChange={this.handleChange}
              value={this.state.dados.req || ""}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              InputProps={{
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />
            <TextField
              label="Família"
              name="fam"
              onChange={this.handleChange}
              value={this.state.dados.fam || ""}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              InputProps={{
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");

                fetch(
                  apiUrl +
                    `/criar-pasta/${window.location.pathname.split("/")[1]}`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify(this.state.dados),
                  }
                ).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    response.json().then((data) => {
                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: [
                            {
                              id: data.id,
                              req: data.req,
                              fam: data.fam,
                              total_itens: 0,
                              created_at: data.created_at,
                            },
                            ...(prevState.data && prevState.data.cartorio
                              ? prevState.data.cartorio
                              : []),
                          ],
                          new: false,
                          dados: {},
                          searchTerm: "",
                          searchResults: prevState.data
                            ? prevState.data.cartorio
                            : [],
                          showSearchResults: false,
                        },
                        new: false,
                      }));
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Folders;
