import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import Folders from "../../Comps/Folders";
import Certs from "../../Certs";
import Carts from "../../Comps/Carts";
import CertCRC from "../../Comps/CertCRC";

class TjEs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "Espírito Santo - Consulta";
    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class=" shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjES{" "}
            </h2>
          </div>
        </header>
        <main>
          <Carts data={this.state.data.cartorio} />

          <Folders />
          <Certs data={this.state.data.certs} />
          <CertCRC data={this.state.data.crc} />
        </main>
        <div id="modal-root"></div>
        <ToastContainer />
      </>
    );
  }
}

export default TjEs;
