import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { QRCodeCanvas } from "qrcode.react";

import { apiUrl } from "../../config";
import { StaticDialog } from "react-st-modal";
import { MenuItem, TextField } from "@mui/material";
class SeloNacional extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      data: {},
      add: false,
      dados: {},
    };
  }

  componentDidMount() {
    fetch(`${apiUrl}/${this.props.token}/selo-nacional`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({ data, Loader: false });
          });
        }
      })
      .catch((error) => {
        toast.warn("Erro ao carregar selo!");
      });

    switch (this.props.sigla) {
      case "funarpen":
        this.setState((prevState) => ({
          dados: {
            ...prevState.dados,
            ["cpf"]: this.props.cert.nmrFol,
            ["data_registro"]: this.props.cert.dataGer,
            ["cns"]: this.props.cartorio.cns,
          },
        }));
        break;
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    // Permite apenas números
    if (/^\d*$/.test(value)) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados,
          [name]: value,
        },
      }));
    }
  };

  render() {
    var data = this.state.data;
    return (
      <>
        <div className="card">
          <div class="card-header d-flex justify-content-between">
            <h3>Selo Nacional:</h3>
            <button
              class="btn  btn-primary"
              disabled={
                Object.keys(data).length > 0 || this.props.resp == false
                  ? true
                  : false
              }
              onClick={(e) => {
                if (this.props.cartorio.cns == null) {
                  toast.warn("CNS do cartório não encontrado!");
                  return;
                }

                this.setState({ add: true });
              }}
            >
              <FontAwesomeIcon icon={faFile} /> Criar Selo
            </button>
          </div>
          <div className="card-body">
            {this.state.Loader == true ? (
              <div className="text-center">Carregando...</div>
            ) : (
              <>
                {" "}
                {Object.keys(data).length > 0 ? (
                  <div>
                    <div className="d-flex  align-items-center">
                      <div>
                        {" "}
                        <QRCodeCanvas
                          id="selo_nacional"
                          value={data.selo_n}
                          size={164}
                          bgColor="#ffffff"
                          fgColor="#000000"
                          includeMargin={true}
                        />
                      </div>
                      <button
                        style={{ marginLeft: "10px" }}
                        className="btn  btn-primary"
                        onClick={() => {
                          const canvas =
                            document.getElementById("selo_nacional");

                          const imageData = canvas.toDataURL("image/png");

                          const img = document.createElement("img");
                          img.src = "data:image/png;base64," + imageData;
                          document.body.appendChild(img);
                          const range = document.createRange();
                          range.selectNode(img);
                          const sel = window.getSelection();
                          sel.removeAllRanges();
                          sel.addRange(range);
                          document.execCommand("copy");
                          document.body.removeChild(img);

                          toast.success(
                            "Imagem copiada para a área de transferência!"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy} /> Copiar
                      </button>

                      <button
                        className="btn  btn-primary"
                        onClick={(e) => {
                          window.open(
                            `https://certidao.registrocivilbr.org/validar/${data.selo_n}`,
                            "_blank"
                          );
                        }}
                        style={{ marginLeft: 10 }}
                      >
                        <FontAwesomeIcon color="#fff" icon={faLink} /> Acessar
                      </button>
                    </div>
                    <div
                      className="mt-2 text-center mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const textArea = document.createElement("textarea"); // Cria um elemento temporário
                        textArea.value = this.state.data.selo_n; // Define o valor como o texto da variável
                        document.body.appendChild(textArea); // Adiciona ao DOM
                        textArea.select(); // Seleciona o texto
                        document.execCommand("copy"); // Copia
                        document.body.removeChild(textArea); // Remove o elemento temporário
                        toast.success("Token copiado com sucesso!");
                      }}
                    >
                      {" "}
                      {this.state.data.selo_n}
                    </div>
                  </div>
                ) : (
                  <span>O selo ainda não foi criado</span>
                )}
              </>
            )}
          </div>
        </div>
        <StaticDialog
          isOpen={this.state.add}
          title="Informações"
          onAfterClose={(result) => {
            this.setState({ add: false });
          }}
        >
          <div className="modal-body" style={{ margin: 10 }}>
            <InputMask
              mask="999.999.999-99"
              value={this.state.dados.cpf || ""}
              onChange={(e) => {
                this.setState((prevState) => ({
                  dados: {
                    ...prevState.dados,
                    ["cpf"]: e.target.value,
                  },
                }));
              }}
              name="cpf"
            >
              {() => (
                <TextField
                  label="CPF"
                  name="cpf"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              )}
            </InputMask>

            <TextField
              select
              label="Selecione um documento"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const options = [
                  { label: "CERTIDÃO EM INTERIOR TEOR NASCIMENTO", value: 1 },
                  { label: "CERTIDÃO EM INTERIOR TEOR CASAMENTO", value: 2 },
                  { label: "CERTIDÃO EM INTERIOR TEOR ÓBITO", value: 4 },
                ];
                const selectedLabel = options.find(
                  (option) => option.value === selectedValue
                ).label;

                this.setState((prevState) => ({
                  dados: {
                    ...prevState.dados,
                    tipo_ato: selectedLabel, // Salva o label
                    tlr: selectedValue, // Salva o número
                  },
                }));
              }}
              fullWidth
              size="small"
              variant="outlined"
            >
              {[
                { label: "CERTIDÃO EM INTERIOR TEOR NASCIMENTO", value: 1 },
                { label: "CERTIDÃO EM INTERIOR TEOR CASAMENTO", value: 2 },
                { label: "CERTIDÃO EM INTERIOR TEOR ÓBITO", value: 4 },
              ].map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.value}. {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Livro"
              name="num_livro"
              value={this.state.dados.num_livro || ""}
              onChange={(e) => this.handleChange(e)}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 5 } }}
              InputProps={{
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />

            <TextField
              label="Folha"
              name="folha"
              value={this.state.dados.folha || ""}
              maxlength={3}
              onChange={(e) => this.handleChange(e)}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 3 } }}
              InputProps={{
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />

            <TextField
              label="Termo"
              name="termo"
              value={this.state.dados.termo || ""}
              maxlength={7}
              onChange={(e) => this.handleChange(e)}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 7 } }}
              InputProps={{
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />

            <TextField
              label="Verificador"
              name="verificador"
              value={this.state.dados.verificador || ""}
              onChange={(e) => this.handleChange(e)}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              slotProps={{ htmlInput: { maxLength: 2 } }}
              InputProps={{
                maxlength: 2,
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />

            <InputMask
              mask="99/99/9999 - 99:99"
              value={this.state.dados.data_registro}
              onChange={(e) => {
                this.setState((prevState) => ({
                  dados: {
                    ...prevState.dados,
                    ["data_registro"]: e.target.value,
                  },
                }));
              }}
            >
              {() => (
                <TextField
                  label="Data de Registro"
                  name="data_registro"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              )}
            </InputMask>

            <TextField
              label="Texto"
              name="texto"
              value={this.state.dados.obs_cert || ""}
              maxlength={5}
              onChange={(e) => {
                const { value } = e.target;
                this.setState((prevState) => ({
                  dados: {
                    ...prevState.dados,
                    ["obs_cert"]: value,
                  },
                }));
              }}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              multiline // Para transformar em textarea
              rows={4} // Define o número de linhas visíveis
              InputProps={{
                style: {
                  height: 100, // Altura ajustada para dar mais espaço
                  padding: "10px", // Ajuste de padding para o layout
                },
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");

                fetch(`${apiUrl}/criar-selo-nacional`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    sigla: this.props.sigla,
                    token: this.props.token,
                    link: this.props.link,
                    cpf: this.state.dados.cpf,
                    num_livro: this.state.dados.num_livro,
                    folha: this.state.dados.folha,
                    termo: this.state.dados.termo,
                    verificador: this.state.dados.verificador,
                    data_registro: this.state.dados.data_registro,
                    obs_cert: this.state.dados.obs_cert,
                    tipo_ato: this.state.dados.tipo_ato,
                    tlr: this.state.dados.tlr,
                    data_registro: this.state.dados.data_registro,
                    cns: this.state.dados.cns,
                  }),
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      response.json().then((selo) => {
                        this.setState({
                          data: { ...data, selo_n: selo.selo_n },
                          add: false,
                        });
                      });
                      toast.success("Selo criado com sucesso!");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default SeloNacional;
