import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import InputMask from "react-input-mask";
import Folders from "../../Comps/Folders";
import Certs from "../../Certs";
import Carts from "../../Comps/Carts";
class TjRj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      currentPage: 1,
      itemsPerPage: 5,
      totalPages: 0,

      currentPage2: 1,
      itemsPerPage2: 5,
      totalPages2: 0,
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
    this.iptCds = React.createRef();
    this.iptCma = React.createRef();
    this.iptCep = React.createRef();
    this.iptBairro = React.createRef();
    this.iptMcp = React.createRef();
  }

  componentDidMount() {
    document.title = "Rio de janeiro - Consulta";
    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({
            data,
            totalPages: data.totalPage,
            totalPages2: data.totalPage2,
            Loader: false,
          });
        });
      }
    });
  }

  fetchItems(page) {
    fetch(
      `${apiUrl}/${
        window.location.pathname.split("/")[1]
      }/certificados/${page}/${this.state.itemsPerPage}`
    )
      .then((response) => response.json())
      .then((resp) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            certs: resp,
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  }

  fetchItems2(page) {
    fetch(
      `${apiUrl}/${
        window.location.pathname.split("/")[1]
      }nt/certificados/${page}/${this.state.itemsPerPage}`
    )
      .then((response) => response.json())
      .then((resp) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            certs2: resp,
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchItems(page);
    });
  };

  handlePageChange2 = (page) => {
    this.setState({ currentPage2: page }, () => {
      this.fetchItems2(page);
    });
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }

    const { totalPages, currentPage, totalPages2, currentPage2 } = this.state;
    const pageNumbers = [];
    const pageNumbers2 = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    for (let i = 1; i <= totalPages2; i++) {
      pageNumbers2.push(i);
    }

    return (
      <>
        <header class=" shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjRj{" "}
            </h2>
          </div>
        </header>
        <main>
          <Carts data={this.state.data.cartorio} />

          <Folders />
          <Certs data={this.state.data.certs2} />
          <div class="py-2">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header d-flex">
                    <div className="area-buscas">
                      <Link
                        class="btn btn-xs btn-primary-2"
                        style={{ background: "#dc3545", color: "#fff" }}
                        to={`${window.location.pathname}/create`}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Certidão CRC
                      </Link>

                      <input
                        type="text"
                        placeholder="Pesquisar:"
                        style={{ marginLeft: 10 }}
                      />
                      <button>
                        <FontAwesomeIcon
                          color="#fff"
                          icon={faMagnifyingGlass}
                        />
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <p>Lista de certidão</p>
                        <div></div>
                        {this.state.data.certs.length > 0 ? (
                          <>
                            <table class="ntbl table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Nome</th>
                                  <th scope="col">Tipo de documento</th>
                                  <th>Selo Nacional</th>
                                  <th>Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.certs.map((item, index) => (
                                  <>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item.participante.nomeP}</td>
                                      <td>{item.tipo_ato}</td>
                                      <td>{item.selo_nacional}</td>
                                      <td>
                                        <div class="btn-group btn-group-sm">
                                          <Link
                                            class="btn btn-success"
                                            to={`/${
                                              window.location.pathname.split(
                                                "/"
                                              )[1]
                                            }/preview/${item.token}`}
                                          >
                                            <FontAwesomeIcon
                                              color="#fff"
                                              icon={faEye}
                                            />
                                          </Link>
                                          <Link
                                            class="btn btn-info"
                                            to={`/${
                                              window.location.pathname.split(
                                                "/"
                                              )[1]
                                            }/edit/${item.token}`}
                                          >
                                            <FontAwesomeIcon
                                              color="#fff"
                                              icon={faPen}
                                            />
                                          </Link>
                                          <button
                                            class="btn btn-danger"
                                            onClick={() => {
                                              Modal.create({
                                                titulo: "Tem certeza?",
                                                html: (
                                                  <>
                                                    <p>
                                                      Após a remoção, não será
                                                      possível recuperar o dado.
                                                    </p>
                                                    <div class="card-footer">
                                                      <button
                                                        type="submit"
                                                        style={{
                                                          background: "#dc3545",
                                                        }}
                                                        class="btn btn-danger float-right ml-2"
                                                        onClick={() => {
                                                          const id =
                                                            toast.loading(
                                                              "Carregando..."
                                                            );
                                                          fetch(
                                                            `${apiUrl}${window.location.pathname}/remover-certificado/${item.id}`,
                                                            {
                                                              method: "DELETE",
                                                              credentials:
                                                                "include",
                                                              headers: {
                                                                "Content-Type":
                                                                  "application/json",
                                                                Authorization:
                                                                  "Bearer " +
                                                                  Cookies.get(
                                                                    "auth_token"
                                                                  ),
                                                              },
                                                            }
                                                          ).then((response) => {
                                                            if (
                                                              response.status ==
                                                              200
                                                            ) {
                                                              toast.update(id, {
                                                                render:
                                                                  "Certificado removido com sucesso!",
                                                                type: "success",
                                                                isLoading: false,
                                                                autoClose: 3000,
                                                              });

                                                              this.setState(
                                                                (
                                                                  prevState
                                                                ) => ({
                                                                  data: {
                                                                    ...prevState.data,
                                                                    certs:
                                                                      prevState.data.certs.filter(
                                                                        (i) =>
                                                                          i.id !==
                                                                          item.id
                                                                      ),
                                                                  },
                                                                })
                                                              );
                                                              Modal.close();
                                                            }
                                                          });
                                                        }}
                                                      >
                                                        <i class="fas fa-download"></i>{" "}
                                                        Remover{" "}
                                                      </button>
                                                    </div>
                                                  </>
                                                ),
                                              });
                                            }}
                                          >
                                            {" "}
                                            <FontAwesomeIcon icon={faTrash} />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>Não há dados cadastrados!</>
                        )}
                      </div>
                    </div>
                  </div>
                  {pageNumbers.length > 0 && (
                    <>
                      <nav className="page-nvgt">
                        <ul class="pagination">
                          <li
                            class="page-item"
                            onClick={() =>
                              this.handlePageChange(currentPage - 1)
                            }
                            disabled={currentPage === 1}
                          >
                            <a class="page-link" href="#">
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </a>
                          </li>

                          {pageNumbers.map((number) => (
                            <li
                              class="page-item"
                              key={number}
                              onClick={() => this.handlePageChange(number)}
                              className={number === currentPage ? "active" : ""}
                            >
                              <a class="page-link" href="#">
                                {" "}
                                {number}
                              </a>
                            </li>
                          ))}
                          <li
                            class="page-item"
                            onClick={() =>
                              this.handlePageChange(currentPage + 1)
                            }
                            disabled={currentPage === totalPages}
                          >
                            <a class="page-link" href="#">
                              <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <div id="modal-root"></div>
        <ToastContainer />
      </>
    );
  }
}

export default TjRj;
