import React from "react";
import { Route, Routes, Router, useNavigate } from "react-router-dom";
import "../src/paginas/Login/l.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import Login from "./paginas/Login";
import Logado from "./paginas/Logado";
import Header from "./Comps/Header";
import Funarpen from "./paginas/Funarpen";
import CreateFun from "./paginas/Funarpen/create";
import TjSp from "./paginas/TjSp";
import PreViewCertPR from "./paginas/Funarpen/PreViewCertPR";
import EditarCertFun from "./paginas/Funarpen/EditarCertFun";
import CreateCertTjSp from "./paginas/TjSp/CreateCertTjSp";
import PreViewCertSp from "./paginas/TjSp/PreViewCertSP";
import EditarCertSp from "./paginas/TjSp/EditarCertSp";
import Profile from "./paginas/Profile";
import TjRJ from "./paginas/TjRJ";
import CreateCertTjRj from "./paginas/TjRJ/CreateCertTjRj";
import PreViewCertRj from "./paginas/TjRJ/PreViewCertRj";
import EditarCertRj from "./paginas/TjRJ/EditarCertRJ";
import TjMg from "./paginas/TjMg";
import CreateCertTjMg from "./paginas/TjMg/CreateCertTjMg";
import PreViewCertMg from "./paginas/TjRJ/PreViewCertMg";
import EditarCertMg from "./paginas/TjMg/EditarCertMg";
import TjSc from "./paginas/TjSc";
import CreateCertTjSc from "./paginas/TjSc/CreateCertTjSc";
import TjGo from "./paginas/TjGo";
import CreateCertTjGo from "./paginas/TjGo/CreateCertTjGo";
import PreViewCertGo from "./paginas/TjGo/PreViewCertGo";
import EditarCertGo from "./paginas/TjGo/EditarCertGo";
import Apostil from "./paginas/Apostil";
import CreateApostil from "./paginas/Apostil/CreateApostil";
import PrevApostil from "./paginas/Apostil/PrevApostil";
import EditApostil from "./paginas/Apostil/EditApostil";
import Secure from "./paginas/Secure";
import TjRs from "./paginas/TjRs";
import CreateCertTjRs from "./paginas/TjRs/CreateCertTjRs";
import PreViewCertRs from "./paginas/TjRs/PreViewCertRs";
import EditarCertRs from "./paginas/TjRs/EditarCertRs";
import PreViewCertSc from "./paginas/TjSc/PreViewCertSc";
import EditarCertSc from "./paginas/TjSc/EditarCertSc";
import NotFound from "./paginas/NotFound";
import TjEs from "./paginas/TjEs";
import CreateCertTjEs from "./paginas/TjRs/CreateCertTjRs/CreateCertTjEs";
import PreViewCertEs from "./paginas/TjEs/PreViewCertEs";
import EditarCertEs from "./paginas/TjEs/EditarCertEs";
import SeloFurnapen from "./paginas/Selofurnapen";
import CreateSeloFun from "./paginas/Selofurnapen/CreateSeloFun";
import PreViewSeloPR from "./paginas/Selofurnapen/PreViewSeloPR";
import EditarSeloFun from "./paginas/Selofurnapen/EditarSeloFun";
import { Confirm, useDialog } from "react-st-modal";
import CreateCertTjRjNt from "./paginas/TjRJ/CreateCertTjRjNt";
import PreViewCertRjNt from "./paginas/TjRJ/PreViewCertRjNt";
import { ToastContainer } from "react-toastify";
import NovoSlc from "./paginas/TjSc/NovoSlc";
import EditSlc from "./paginas/TjSc/EditSlc";
import StampView from "./paginas/StampView";
import Pastas from "./paginas/Pastas";
import CreateCart from "./Comps/CreateCart";
function App() {
  const navigateTo = useNavigate();
  const dialog = useDialog();

  return (
    <>
      {Cookies.get("auth_token") != undefined ? (
        <>
          <Header />
          <Routes>
            <Route exact path="/" element={<Logado navigate={navigateTo} />} />
            <Route path="*" element={<NotFound />} />

            <Route
              path="/apostil"
              element={<Apostil navigate={navigateTo} />}
            />
            <Route
              path="/apostil/create"
              element={<CreateApostil navigate={navigateTo} />}
            />
            <Route
              path="/apostil/preview/:token"
              element={<PrevApostil navigate={navigateTo} />}
            />
            <Route
              path="/apostil/edit/:token"
              element={<EditApostil navigate={navigateTo} />}
            />

            <Route path="/funarpen" element={<Funarpen />} />
            <Route
              path="/funarpen/create*"
              element={<CreateFun navigate={navigateTo} />}
            />
            <Route
              path="/funarpen/preview/:token"
              element={<PreViewCertPR navigate={navigateTo} />}
            />
            <Route
              path="/funarpen/edit/:token"
              element={<EditarCertFun navigate={navigateTo} />}
            />

            <Route
              path="/:sigla/novo/cartorio"
              element={<CreateCart navigate={navigateTo} />}
            />

            <Route
              path="/funarpen/propriedade/:token"
              element={<SeloFurnapen navigate={navigateTo} />}
            />
            <Route
              path="/funarpen/criar-propriedade/:token"
              element={<CreateSeloFun navigate={navigateTo} />}
            />
            <Route
              path="/funarpen/selo/preview/:token"
              element={<PreViewSeloPR navigate={navigateTo} />}
            />
            <Route
              path="/funarpen/selo/edit/:token"
              element={<EditarSeloFun navigate={navigateTo} />}
            />

            <Route path="/tjsp" element={<TjSp navigate={navigateTo} />} />
            <Route
              path="/tjsp/create*"
              element={<CreateCertTjSp navigate={navigateTo} />}
            />
            <Route
              path="/tjsp/preview/:token"
              element={<PreViewCertSp navigate={navigateTo} />}
            />
            <Route
              path="/tjsp/edit/:token"
              element={<EditarCertSp navigate={navigateTo} />}
            />

            <Route path="/tjrj" element={<TjRJ navigate={navigateTo} />} />
            <Route
              path="/tjrj/create"
              element={<CreateCertTjRj navigate={navigateTo} />}
            />
            <Route
              path="/tjrj/create-natalina"
              element={<CreateCertTjRjNt navigate={navigateTo} />}
            />
            <Route
              path="/tjrj/preview/:token"
              element={<PreViewCertRj navigate={navigateTo} />}
            />
            <Route
              path="/tjrjnt/preview/:token"
              element={<PreViewCertRjNt navigate={navigateTo} />}
            />

            <Route
              path="/tjrj/edit/:token"
              element={<EditarCertRj navigate={navigateTo} />}
            />

            <Route path="/tjmg" element={<TjMg navigate={navigateTo} />} />
            <Route
              path="/tjmg/create"
              element={<CreateCertTjMg navigate={navigateTo} />}
            />
            <Route
              path="/tjmg/preview/:token"
              element={<PreViewCertMg navigate={navigateTo} />}
            />
            <Route
              path="/tjmg/edit/:token"
              element={<EditarCertMg navigate={navigateTo} />}
            />

            <Route path="/tjsc" element={<TjSc navigate={navigateTo} />} />
            <Route
              path="/tjsc/create"
              element={<CreateCertTjSc navigate={navigateTo} />}
            />
            <Route
              path="/:token/pasta/:id"
              element={<Pastas navigate={navigateTo} />}
            />
            <Route
              path="/tjsc/novo-solicitante"
              element={<NovoSlc navigate={navigateTo} />}
            />
            <Route
              path="/tjsc/edit/solicitante/:token"
              element={<EditSlc navigate={navigateTo} />}
            />
            <Route
              path="/tjsc/preview/:token"
              element={<PreViewCertSc navigate={navigateTo} />}
            />
            <Route
              path="/tjsc/edit/:token"
              element={<EditarCertSc navigate={navigateTo} />}
            />

            <Route path="/tjgo" element={<TjGo navigate={navigateTo} />} />
            <Route
              path="/tjgo/create"
              element={<CreateCertTjGo navigate={navigateTo} />}
            />
            <Route
              path="/tjgo/preview/:token"
              element={<PreViewCertGo navigate={navigateTo} />}
            />
            <Route
              path="/tjgo/edit/:token"
              element={<EditarCertGo navigate={navigateTo} />}
            />

            <Route path="/tjrs" element={<TjRs navigate={navigateTo} />} />
            <Route
              path="/tjrs/create"
              element={<CreateCertTjRs navigate={navigateTo} />}
            />
            <Route
              path="/tjrs/preview/:token"
              element={<PreViewCertRs navigate={navigateTo} />}
            />
            <Route
              path="/tjrs/edit/:token"
              element={<EditarCertRs navigate={navigateTo} />}
            />

            <Route path="/tjes" element={<TjEs navigate={navigateTo} />} />
            <Route
              path="/tjes/create"
              element={<CreateCertTjEs navigate={navigateTo} />}
            />
            <Route
              path="/tjes/preview/:token"
              element={<PreViewCertEs navigate={navigateTo} />}
            />
            <Route
              path="/tjes/edit/:token"
              element={<EditarCertEs navigate={navigateTo} />}
            />
          </Routes>
          <ToastContainer />
        </>
      ) : (
        <>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/stamp-view/:token" element={<StampView />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
