import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faPlus,
  faMagnifyingGlass,
  faSave,
  faFileText,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Comps/Modal";
import { StaticDialog } from "react-st-modal";
import Folders from "../../Comps/Folders";
import Certs from "../../Certs";
import Carts from "../../Comps/Carts";
import CertCRC from "../../Comps/CertCRC";

class TjRs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      modal: false,
      edit: false,
      item: {},
      cartorio: { nome: "", edrc: "", cidade: "", serventia: "" },
    };
    this.iptNome = React.createRef();
    this.iptResp = React.createRef();
    this.iptEdrc = React.createRef();
    this.iptCidade = React.createRef();
    this.iptTelefone = React.createRef();
    this.iptCns = React.createRef();
  }

  componentDidMount() {
    document.title = "Rio Grande Do Sul - Consulta";
    fetch(`${apiUrl}/list` + window.location.pathname, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      cartorio: {
        ...prevState.cartorio,
        [name]: value,
      },
    }));
  };

  handleChange2 = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              TjRs{" "}
            </h2>
          </div>
        </header>
        <main>
          <Carts data={this.state.data.cartorio} />
          <Folders />
          <Certs data={this.state.data.certs} />
          <CertCRC data={this.state.data.crc} />
        </main>
        <div id="modal-root"></div>

        <StaticDialog
          isOpen={this.state.edit}
          title="Editar cartório"
          onAfterClose={(result) => {
            this.setState({ edit: false, item: {} });
          }}
        >
          <div style={{ padding: 10 }}>
            <div class="form-group mt-2">
              <label>Nome do Ofício:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                value={this.state.item.nome != null ? this.state.item.nome : ""}
                name="nome"
                onChange={this.handleChange2}
              />
            </div>

            <div class="form-group mt-2">
              <label>
                Endereço: (Sem colocar <strong>Bairro</strong>)
              </label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="edrc"
                value={this.state.item.edrc != null ? this.state.item.edrc : ""}
                onChange={this.handleChange2}
              />
            </div>

            <div class="form-group mt-2">
              <label>Cidade:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="cidade"
                value={
                  this.state.item.cidade != null ? this.state.item.cidade : ""
                }
                onChange={this.handleChange2}
              />
            </div>

            <div class="form-group mt-2">
              <label>Cns:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="Cns"
                value={this.state.item.Cns != null ? this.state.item.Cns : ""}
                onChange={this.handleChange2}
              />
            </div>

            <div class="form-group mt-2">
              <div class="form-group mt-2">
                <label>Serventia:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="codeser"
                  value={
                    this.state.item.codeser != null
                      ? this.state.item.codeser
                      : ""
                  }
                  onChange={this.handleChange2}
                />
              </div>
            </div>
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
              marginRight: 20,
            }}
          >
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");

                var cart = this.state.item;

                fetch(
                  `${apiUrl}${window.location.pathname}/atualizar-cartorio/${this.state.item.id}`,
                  {
                    method: "PUT",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc: cart.nome,
                      Edrc: cart.edrc,
                      Cidade: cart.cidade,
                      Cns: cart.Cns,
                      codeser: cart.codeser,
                    }),
                  }
                ).then((response) => {
                  if (response.status == 200) {
                    e.target.classList.remove("load");
                    response.json().then((data) => {
                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: prevState.data.cartorio.map((i) =>
                            i.id === this.state.item.id
                              ? {
                                  ...i,
                                  nome: cart.nome,
                                  edrc: cart.edrc,
                                  cidade: cart.cidade,
                                  Cns: cart.Cns,
                                  codeser: cart.codeser,
                                }
                              : i
                          ),
                        },
                        edit: false,
                        item: {},
                      }));
                      toast.success("Atualizado!");
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.modal}
          title="Adicionar cartório"
          onAfterClose={(result) => {
            this.setState({ modal: false });
            // do something with dialog result
          }}
        >
          <div style={{ padding: 10 }}>
            <div class="form-group mt-2">
              <label>Nome do Ofício:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="nome"
                onChange={this.handleChange}
              />
            </div>

            <div class="form-group mt-2">
              <label>
                Endereço: (Sem colocar <strong>Bairro</strong>)
              </label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="edrc"
                onChange={this.handleChange}
              />
            </div>

            <div class="form-group mt-2">
              <label>Cidade:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="cidade"
                onChange={this.handleChange}
              />
            </div>

            <div class="form-group mt-2">
              <label>Cns:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                name="Cns"
                onChange={this.handleChange}
              />
            </div>

            <div class="form-group mt-2">
              <div class="form-group mt-2">
                <label>Serventia:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  name="serventia"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
              marginRight: 20,
            }}
          >
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");
                var cart = this.state.cartorio;
                fetch(
                  `${apiUrl}${window.location.pathname}/adicionar-cartorio/`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nomeOfc: cart.nome,
                      Edrc: cart.edrc,
                      Cidade: cart.cidade,
                      Cns: cart.Cns,
                      codeser: cart.serventia,
                    }),
                  }
                ).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status == 200) {
                    response.json().then((data) => {
                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          cartorio: [
                            ...prevState.data.cartorio,
                            {
                              id: data.id,
                              nome: cart.nome,
                              edrc: cart.edrc,
                              codeser: cart.serventia,
                            },
                          ],
                        },
                        modal: false,
                      }));
                      toast.success("Salvo!");
                    });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <ToastContainer />
      </>
    );
  }
}

export default TjRs;
