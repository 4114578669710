import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faPlus,
  faArrowRight,
  faSave,
  faSearch,
  faFileText,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { QRCodeCanvas } from "qrcode.react";
import InputMask from "react-input-mask";

import { StaticDialog } from "react-st-modal";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link, json } from "react-router-dom";
import { create } from "@mui/material/styles/createTransitions";
import { apiUrl } from "../config";

class Certs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      new: false,
      trash: {},
      searchTerm: "",
      searchResults: [],
      showSearchResults: false,
      trash: {},
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };
  formatarDataBR(isoDate) {
    return new Date(isoDate).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    this.setState({ searchTerm }, () => {
      if (searchTerm.length > 0) {
        const searchResults = this.state.data.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchTerm)
          )
        );

        this.setState({
          searchResults,
          showSearchResults: true,
          currentPage: 0,
        });
      } else {
        this.setState({
          searchResults: [],
          showSearchResults: false,
          currentPage: 0,
        });
      }
    });
  };

  _ButtonAction = (item) => {
    return (
      <div class="d-flex">
        <Link
          class="btn btn-primary"
          to={`/${window.location.pathname.split("/")[1]}/preview/${
            item.token
          }`}
        >
          <FontAwesomeIcon color="#fff" icon={faArrowRight} /> Acessar
        </Link>

        <button
          style={{ marginLeft: "10px" }}
          class="btn btn-danger"
          onClick={() => {
            this.setState({ trash: item });
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faTrash} /> Remover
        </button>
      </div>
    );
  };

  render() {
    const {
      currentPage,
      itemsPerPage,
      searchTerm,
      searchResults,
      showSearchResults,
    } = this.state;
    const data = showSearchResults ? searchResults : this.state.data || []; // Agora data vem das props

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dataPaginada = data.slice(startIndex, endIndex);

    return (
      <>
        <div className="py-12">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-3 w-100">
                    <Link
                      className="btn btn-danger d-flex align-items-center gap-2"
                      to={`/${window.location.pathname.split("/")[1]}/create`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Adicionar Certidão
                    </Link>

                    <div className="input-group w-50">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar:"
                        value={searchTerm}
                        onChange={this.handleSearch}
                      />
                      <button className="btn btn-primary">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  {data.length === 0 ? (
                    <>
                      {" "}
                      <div className="text-center">
                        {searchTerm && searchTerm.length > 0
                          ? "Nenhuma pasta encontrada."
                          : "Nenhuma Pasta Encontrada"}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3">
                        <strong>Lista de Pastas</strong>
                      </div>
                      <table className="ntbl table table-sm table-bordered">
                        {window.location.pathname.split("/")[1] ===
                          "funarpen" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Tipo de documento</th>
                                <th scope="col">Propriedade</th>
                                <th scope="col">Selo Nacional</th>
                                <th scope="col">Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{item.nomeCont}</td>
                                    <td>{item.docEmt}</td>
                                    <td>
                                      <Link
                                        to={`/funarpen/propriedade/${item.token}`}
                                      >
                                        Acessar
                                      </Link>
                                    </td>
                                    <td>{item.selo_nacional}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjsp" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Iniciais do requerente</th>

                                <th scope="col">Tipo de documento</th>
                                <th>Selo Nacional</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.iniReq}</td>

                                    <td>{item.docEmt}</td>
                                    <td>{item.selo_nacional}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjmg" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">nome</th>
                                <th scope="col">Tipo de livro</th>
                                <th>Selo Nacional</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome_pessoa}</td>
                                    <td>{item.tipo_de_livro}</td>
                                    <td>{item.selo_nacional}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjgo" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Civil do ato</th>
                                <th scope="col">Tipo de documento</th>
                                <th>Selo Nacional</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.civilato}</td>
                                    <td>{item.docEmt}</td>
                                    <td>{item.selo_nacional}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjrs" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>

                                <th scope="col">Tipo de certidão:</th>
                                <th scope="col">Data de Emissão:</th>
                                <th>Chave:</th>
                                <th scope="col">Documento:</th>
                                <th>Selo Nacional</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>

                                    <td>{item.tipo_certidao}</td>
                                    <td>{item.emss}</td>
                                    <td>{item.seg}</td>
                                    <td>
                                      <a
                                        href={`${apiUrl}/tjrs/view/doc/${item.file}`}
                                        className="btn btn-success"
                                        target="_blank"
                                      >
                                        <FontAwesomeIcon icon={faFileText} />{" "}
                                        Visualizar
                                      </a>
                                    </td>
                                    <td>{item.selo_nacional}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] == "tjsc" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome na Certidão</th>
                                <th scope="col">Tipo de Documento</th>
                                <th>Selo Estadual - Emissor</th>
                                <th>Selo Nacional</th>

                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.titular}</td>
                                    <td>{item.tipo_ato}</td>
                                    <td>{item.token}</td>
                                    <td>{item.selo_nacional}</td>

                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjes" && (
                          <>
                            {" "}
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">nome</th>
                                <th scope="col">Ato</th>
                                <th>Selo Nacional</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.nome}</td>
                                    <td>{item.ato}</td>
                                    <td>{item.selo_nacional}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                        {window.location.pathname.split("/")[1] === "tjrj" && (
                          <>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Tipo de documento</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataPaginada.map((item, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.participante.nomeP}</td>
                                    <td>{item.tipo_ato}</td>
                                    <td>{this._ButtonAction(item)}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                      </table>
                      {data.length > 0 && (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-secondary mx-1"
                            disabled={currentPage === 0}
                            onClick={() =>
                              this.handlePageChange(currentPage - 1)
                            }
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> Anterior
                          </button>

                          {[...Array(totalPages).keys()].map((page) => {
                            const isFirstPages = page < 3;
                            const isLastPages = page >= totalPages - 3;
                            const isAroundCurrent =
                              Math.abs(currentPage - page) <= 2;

                            if (
                              isFirstPages ||
                              isLastPages ||
                              isAroundCurrent
                            ) {
                              return (
                                <button
                                  key={page}
                                  className={`btn mx-1 ${
                                    currentPage === page
                                      ? "btn-primary"
                                      : "btn-outline-secondary"
                                  }`}
                                  onClick={() => this.handlePageChange(page)}
                                >
                                  {page + 1}
                                </button>
                              );
                            } else if (
                              (page === 3 && currentPage > 5) ||
                              (page === totalPages - 4 &&
                                currentPage < totalPages - 5)
                            ) {
                              return (
                                <span key={page} className="mx-2">
                                  ...
                                </span>
                              );
                            }

                            return null;
                          })}

                          <button
                            className="btn btn-secondary mx-1"
                            disabled={currentPage === totalPages - 1}
                            onClick={() =>
                              this.handlePageChange(currentPage + 1)
                            }
                          >
                            Próximo <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <StaticDialog
            isOpen={Object.keys(this.state.trash).length > 0}
            title="Atenção"
            onAfterClose={(result) => {
              this.setState({ trash: {} });
            }}
          >
            <div
              className="modal-body"
              style={{ textAlign: "center", margin: 10 }}
            >
              {" "}
              Tem certeza que deseja excluir?
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => {
                  this.setState((prevState) => ({
                    ...prevState,
                    searchResults: prevState.searchResults.filter(
                      (item) => item.token !== this.state.trash.token
                    ),
                    trash: {},
                  }));

                  this.setState((prevState) => ({
                    ...prevState,
                    data: prevState.data.filter(
                      (item) => item.token !== this.state.trash.token
                    ),
                    trash: {},
                  }));

                  toast.success("Certidão excluida com sucesso!");
                }}
              >
                <FontAwesomeIcon icon={faTrash} /> Excluir
              </button>
            </div>
          </StaticDialog>
        </div>
      </>
    );
  }
}

export default Certs;
