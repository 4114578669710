import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faPlus,
  faArrowRight,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { QRCodeCanvas } from "qrcode.react";
import InputMask from "react-input-mask";
import { apiUrl } from "../../config";
import { StaticDialog } from "react-st-modal";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
class Natalina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      data: {},
      new: false,
      estado: "",

      selo_estadual: "",
      createSelo: false,
      cartorios: [],
      dados: {},
      selo_crc: {},
    };
  }

  Links(sigla) {
    switch (sigla) {
      case "tjsp":
        return "https://tjspjus.com/?r=";
        break;
      case "funarpen":
        return "https://selofunarpen.com/consulta/Selo/";
        break;
      case "tjes":
        return "https://tjesjus.com/consulta/default.aspx?selo=";
        break;
      case "tjgo":
        return "https://tjgojus.com/verificacao/";
        break;
      case "tjmg":
        return "https://tjmgjus.com/sisnor/eselo/consultaSeloseAtos.jsf?selo=";
        break;
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados,
        [name]: value,
      },
    }));
  };
  componentDidMount() {
    fetch(`${apiUrl}/crc/view/${window.location.pathname.split("/")[3]}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ Loader: false, selo_crc: data });
          this.props.resp(true);
        });
      }
      if (response.status == 404) {
        this.setState({ Loader: false, selo_crc: {} });
        this.props.resp(false);
      }
    });
  }

  handleSave = (e) => {
    var b = this.state.busca;
    var a = window.location.pathname.split("/")[1];

    fetch(
      `${apiUrl}/create-cert-crc/${window.location.pathname.split("/")[1]}/${
        this.state.estado
      }`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
        body: JSON.stringify({
          ...this.state.dados,
          selo_origem: window.location.pathname.split("/").pop(),
        }),
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          toast.success("Certidão criada com sucesso");
          this.setState({
            dados: {},
            busca: undefined,
            selo_crc: {
              token: data.token,
            },
          });
          this.props.resp(true);
        });
      }
      if (response.status == 404) {
        toast.error("Erro ao criar certidão");
      }
    });
  };

  render() {
    var selo = this.state.selo_crc;

    return (
      <>
        <div className="card">
          <div class="card-header d-flex justify-content-between">
            <h3>Certidão CRC:</h3>
            <button
              disabled={
                Object.keys(this.state.selo_crc).length == 0 ? false : true
              }
              class="btn  btn-primary"
              onClick={(e) => {
                this.setState({ new: true });
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Criar
            </button>
          </div>
          <div className="card-body">
            {this.state.Loader == true ? (
              <div className="text-center">Carregando...</div>
            ) : (
              <>
                {Object.keys(this.state.selo_crc).length == 0 ? (
                  <>O selo ainda não foi criado</>
                ) : (
                  <>
                    <div className="d-flex  align-items-center">
                      <div>
                        {" "}
                        <QRCodeCanvas
                          id="selo_nacional"
                          value={selo.token}
                          size={164}
                          bgColor="#ffffff"
                          fgColor="#000000"
                          includeMargin={true}
                        />
                      </div>
                      <button
                        style={{ marginLeft: "10px" }}
                        className="btn  btn-primary"
                        onClick={() => {
                          const canvas =
                            document.getElementById("selo_nacional");

                          const imageData = canvas.toDataURL("image/png");

                          const img = document.createElement("img");
                          img.src = "data:image/png;base64," + imageData;
                          document.body.appendChild(img);
                          const range = document.createRange();
                          range.selectNode(img);
                          const sel = window.getSelection();
                          sel.removeAllRanges();
                          sel.addRange(range);
                          document.execCommand("copy");
                          document.body.removeChild(img);

                          toast.success(
                            "Imagem copiada para a área de transferência!"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy} /> Copiar
                      </button>

                      <button
                        className="btn  btn-primary"
                        onClick={(e) => {
                          console.log(selo.sigla);

                          window.open(
                            `${this.Links(selo.sigla)}${selo.token}`,
                            "_blank"
                          );
                        }}
                        style={{ marginLeft: 10 }}
                      >
                        <FontAwesomeIcon color="#fff" icon={faLink} /> Acessar
                      </button>
                    </div>
                    <div
                      className="mt-2 text-center mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const textArea = document.createElement("textarea"); // Cria um elemento temporário
                        textArea.value = selo.token; // Define o valor como o texto da variável
                        document.body.appendChild(textArea); // Adiciona ao DOM
                        textArea.select(); // Seleciona o texto
                        document.execCommand("copy"); // Copia
                        document.body.removeChild(textArea); // Remove o elemento temporário
                        toast.success("Token copiado com sucesso!");
                      }}
                    >
                      {" "}
                      {selo.token}
                    </div>{" "}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <StaticDialog
          isOpen={this.state.new}
          title="Criar Certidão CRC"
          onAfterClose={(result) => {
            this.setState({ new: false });
          }}
        >
          <div className="modal-body" style={{ margin: 10 }}>
            <select
              name="estado"
              className="form-control"
              id="estado"
              onChange={(e) => {
                this.setState({ estado: e.target.value });
              }}
            >
              <option value="">Selecione um estado</option>
              <option value="tjsp">São Paulo</option>
              <option value="tjgo">Goiais</option>
              <option value="tjmg">Minas Gerais</option>
              <option value="tjes">Espirito Santo</option>
              <option value="funarpen">Parana</option>
            </select>
          </div>

          <div className="modal-footer">
            <button
              class="btn  btn-primary"
              onClick={(e) => {
                if (this.state.estado == "") {
                  toast.warn("Selecione um estado");
                  return;
                }

                e.target.classList.add("load");
                fetch(`${apiUrl}/listar-cartorios/${this.state.estado}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("auth_token"),
                  },
                  credentials: "include",
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status == 200) {
                    response.json().then((data) => {
                      this.setState({
                        new: false,
                        busca: this.state.estado,
                        cartorios: data,
                      });
                    });
                  }
                });
              }}
            >
              Próximo <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.busca != undefined}
          title={`Criar Selo Materializador  "${this.state.busca}"`}
          onAfterClose={(result) => {
            this.setState({ busca: undefined });
          }}
        >
          {this.state.estado == "tjsp" && (
            <>
              <div className="modal-body" style={{ margin: 10 }}>
                <TextField
                  select // Ativa o modo select no TextField
                  label="Nome da Serventia"
                  name="nomeSer"
                  value={this.state.valorSelecionado}
                  onChange={(event) => {
                    const { value } = event.target;
                    var find = this.state.cartorios.find(
                      (cartorio) => cartorio.id == value
                    );

                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados,
                        ["nomeSer"]: value,
                        ["cns"]: find.Cns,
                      },
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  {this.state.cartorios.map((cartorio) => (
                    <MenuItem key={cartorio.id} value={cartorio.id}>
                      {cartorio.nome}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  label="Tipo de Ato"
                  name="docEmt"
                  value={this.state.valorSelecionado}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                >
                  <MenuItem value="CERTIDÃO EM INTEIRO TEOR NASCIMENTO">
                    CERTIDÃO EM INTEIRO TEOR NASCIMENTO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO EM INTEIRO TEOR CASAMENTO">
                    CERTIDÃO EM INTEIRO TEOR CASAMENTO
                  </MenuItem>

                  <MenuItem value="CERTIDÃO EM INTEIRO TEOR ÓBITO">
                    CERTIDÃO EM INTEIRO TEOR ÓBITO
                  </MenuItem>

                  <MenuItem value="ESCRITURA PÚBLICA DE COMPRA E VENDA DE IMÓVEL">
                    ESCRITURA PÚBLICA DE COMPRA E VENDA DE IMÓVEL
                  </MenuItem>

                  <MenuItem value="CERTIDÃO">CERTIDÃO</MenuItem>
                  <MenuItem value="CERTIDÃO DE NASCIMENTO">
                    CERTIDÃO DE NASCIMENTO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE CASAMENTO">
                    CERTIDÃO DE CASAMENTO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE ÓBITO">
                    CERTIDÃO DE ÓBITO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE NASCIMENTO – EM BREVE RELATO">
                    CERTIDÃO DE NASCIMENTO – EM BREVE RELATO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE CASAMENTO – EM BREVE RELATO">
                    CERTIDÃO DE CASAMENTO – EM BREVE RELATO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO">
                    CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR">
                    CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR">
                    CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR
                  </MenuItem>
                  <MenuItem value="CERTIDÃO DE ÓBITO - EM INTEIRO TEOR">
                    CERTIDÃO DE ÓBITO - EM INTEIRO TEOR
                  </MenuItem>
                  <MenuItem value="PROCURAÇÃO PÚBLICA">
                    PROCURAÇÃO PÚBLICA
                  </MenuItem>
                  <MenuItem value="ESCRITURA PÚBLICA">
                    ESCRITURA PÚBLICA
                  </MenuItem>
                  <MenuItem value="DECLARAÇÃO">DECLARAÇÃO</MenuItem>
                  <MenuItem value="PROCURAÇÃO">PROCURAÇÃO</MenuItem>
                  <MenuItem value="ESCRITURA PÚBLICA DECLARATÓRIA DE MATERNIDADE">
                    ESCRITURA PÚBLICA DECLARATÓRIA DE MATERNIDADE
                  </MenuItem>
                  <MenuItem value="ESCRITURA PÚBLICA DECLARATÓRIA DE PATERNIDADE">
                    ESCRITURA PÚBLICA DECLARATÓRIA DE PATERNIDADE
                  </MenuItem>
                  <MenuItem value="ESCRITURA PÚBLICA DE DIVÓRCIO CONSENSUAL">
                    ESCRITURA PÚBLICA DE DIVÓRCIO CONSENSUAL
                  </MenuItem>
                  <MenuItem value="ESCRITURA PÚBLICA DE UNIÃO ESTÁVEL">
                    ESCRITURA PÚBLICA DE UNIÃO ESTÁVEL
                  </MenuItem>
                </TextField>

                <TextField
                  label="CNS"
                  name="cns"
                  disabled
                  value={this.state.dados.cns || ""}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Iniciais do Requerente:"
                  name="IniReq"
                  value={this.state.dados.IniReq || ""}
                  onChange={(e) => {
                    var value = e.target.value;
                    this.setState({
                      dados: {
                        ...this.state.dados,
                        ["IniReq"]: value.toUpperCase(),
                      },
                    });
                  }}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Número CPF/CNPJ:"
                  name="nmrDoc"
                  onChange={this.handleChange}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  type="datetime-local"
                  label="Data e Hora do Ato Praticado:"
                  variant="outlined"
                  name="dataHora"
                  value={this.state.dados.dataHora || "a"}
                  onChange={this.handleChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Valor do Ato:"
                  onChange={this.handleChange}
                  name="valor"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Ato vinculador"
                  name="atovi"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              </div>
              <div className="modal-footer">
                <button class="btn   btn-primary" onClick={this.handleSave}>
                  <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                </button>
              </div>
            </>
          )}
          {this.state.estado == "tjgo" && (
            <>
              <div className="modal-body" style={{ margin: 10 }}>
                <TextField
                  label="Cívil do Ato"
                  name="civilato"
                  onChange={this.handleChange}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  select // Ativa o modo select no TextField
                  label="Nome da Serventia"
                  name="nomeSer"
                  value={this.state.valorSelecionado}
                  onChange={(event) => {
                    const { value } = event.target;
                    var find = this.state.cartorios.find(
                      (cartorio) => cartorio.id == value
                    );

                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados,
                        ["nomeSer"]: value,
                        // ["cns"]: find.Cns,
                      },
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  {this.state.cartorios.map((cartorio) => (
                    <MenuItem key={cartorio.id} value={cartorio.id}>
                      {cartorio.nome}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Tipo de Ato"
                  name=""
                  value="3001 - 83 V - Segundas vias de certidão de nascimento, casamento, óbito e certidões negativas"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  disabled
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Nome do serventuário que praticou o ato:"
                  name="resp"
                  onChange={this.handleChange}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Emolumento"
                  name="selo_estadual"
                  value="R$ 51,65"
                  disabled
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Taxa do Judiciário"
                  name="selo_estadual"
                  value="R$ 18,29"
                  disabled
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Fundos Estaduais"
                  value="R$ 10,98"
                  disabled
                  name="selo_estadual"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Endereço"
                  name="selo_estadual"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Cidade/UF"
                  name="selo_estadual"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Telefone"
                  name="selo_estadual"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Data"
                  name="data"
                  onChange={this.handleChange}
                  value={this.state.dados.data || "a"}
                  type="date"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              </div>
              <div className="modal-footer">
                <button class="btn   btn-primary" onClick={this.handleSave}>
                  <FontAwesomeIcon icon={faSave} /> Salvar{" "}
                </button>
              </div>
            </>
          )}

          {this.state.estado == "tjmg" && (
            <>
              <div className="modal-body" style={{ margin: 10 }}>
                <TextField
                  label="Referência"
                  name=""
                  value="2025"
                  disabled
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Código Fical do Ato"
                  name=""
                  value="7803"
                  disabled
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Código de Segurança"
                  name=""
                  value="Automático"
                  disabled
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  select // Ativa o modo select no TextField
                  label="Nome da Serventia"
                  name="nomeSer"
                  value={this.state.valorSelecionado}
                  onChange={(event) => {
                    const { value } = event.target;
                    var find = this.state.cartorios.find(
                      (cartorio) => cartorio.id == value
                    );

                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados,
                        ["nomeSer"]: value,
                        ["cns"]: find.Cns,
                      },
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  {this.state.cartorios.map((cartorio) => (
                    <MenuItem key={cartorio.id} value={cartorio.id}>
                      {cartorio.nome}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Data da Prática do Ato"
                  name=""
                  value="Automático"
                  type="date"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Data e Hora de Utilização do Selo:"
                  name=""
                  value="Automático"
                  type="datetime-local"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Data e Hora de Transmissão do Ato"
                  name=""
                  value="Automático"
                  type="datetime-local"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Tipo de Tributação"
                  name=""
                  value="NORMAL"
                  disabled
                  type="text"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Valor do Emolumento"
                  name=""
                  value="R$ 96,84"
                  disabled
                  type="text"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Valor da Taxa de Fiscalização Judiciária:"
                  name=""
                  value="R$ 19,55"
                  disabled
                  type="text"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Valor Final do Usuário:"
                  name=""
                  value="R$ 116,39"
                  disabled
                  type="text"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Protocolo"
                  name=""
                  value="Automático"
                  disabled
                  type="text"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />

                <TextField
                  label="Detalhamento da Certidão"
                  name="detalhamento_certidao"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                  value="8 CERTIDOES: 81 CERTIDAO DE LIVROS: 812 etc"
                />

                <TextField
                  label="Nome da Pessoa"
                  name="nome_pessoa"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="CPF"
                  name="cpf"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 11 }}
                />

                <TextField
                  label="Complemento da Certidão"
                  name="complemento_certidao"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                  value="Certidão de atos praticados na própria Serventia"
                />

                <TextField
                  label="Nome do Responsável pela Prática do Ato"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />

                <TextField
                  label="Qualificações do Responsável pela Prática do Ato"
                  name="resp_ptca"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />

                <TextField
                  select
                  label="Tipo de Livro"
                  name="nomeSer"
                  value={this.state.valorSelecionado}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="">Selecione:</MenuItem>
                  <MenuItem value="701">701 - A - Nascimento</MenuItem>
                  <MenuItem value="702">702 - B - Casamento</MenuItem>
                  <MenuItem value="703">
                    703 - B-Aux Casamento religioso
                  </MenuItem>
                  <MenuItem value="704">704 - C - Óbito</MenuItem>
                  <MenuItem value="705">705 - C-Aux - Natimorto</MenuItem>
                  <MenuItem value="706">706 - D - Proclamas</MenuItem>
                  <MenuItem value="707">
                    707 - E - Inscrição Registro Civil
                  </MenuItem>
                </TextField>

                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={this.handleSave}>
                    <FontAwesomeIcon icon={faSave} /> Salvar
                  </button>
                </div>
              </div>
            </>
          )}

          {this.state.estado == "tjes" && (
            <>
              <div className="modal-body" style={{ margin: 10 }}>
                <TextField
                  label="Nome"
                  name="nome"
                  value={this.state.nome}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  select // Ativa o modo select no TextField
                  label="Nome da Serventia"
                  name="nomeSer"
                  value={this.state.valorSelecionado}
                  onChange={(event) => {
                    const { value } = event.target;
                    var find = this.state.cartorios.find(
                      (cartorio) => cartorio.id == value
                    );

                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados,
                        ["codeSer"]: value,
                        ["cns"]: find.Cns,
                      },
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  {this.state.cartorios.map((cartorio) => (
                    <MenuItem key={cartorio.id} value={cartorio.id}>
                      {cartorio.nome}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Tipo do ato"
                  name="ato"
                  value={this.state.ato}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Selecione:">Selecione:</MenuItem>
                  <MenuItem value="CERTIDÃO EM INTEIRO TEOR NASCIMENTO">
                    CERTIDÃO EM INTEIRO TEOR NASCIMENTO
                  </MenuItem>
                  <MenuItem value="CERTIDÃO EM INTEIRO TEOR CASAMENTO">
                    CERTIDÃO EM INTEIRO TEOR CASAMENTO
                  </MenuItem>

                  <MenuItem value="CERTIDÃO EM INTEIRO TEOR ÓBITO">
                    CERTIDÃO EM INTEIRO TEOR ÓBITO
                  </MenuItem>
                  <MenuItem value="Processamento de dados">
                    Processamento de dados
                  </MenuItem>
                  <MenuItem value="Certidão de casamento, nascimento e óbito - breve relato">
                    Certidão de casamento, nascimento e óbito - breve relato
                  </MenuItem>
                  <MenuItem value="Certidão de casamento, nascimento e óbito - inteiro teor">
                    Certidão de casamento, nascimento e óbito - inteiro teor
                  </MenuItem>
                </TextField>
                <TextField
                  label="Livro"
                  name="livro"
                  value={this.state.livro}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Termo"
                  name="termo"
                  value={this.state.termo}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Folha"
                  name="folha"
                  value={this.state.folha}
                  onChange={this.handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Data"
                  name="data"
                  value={this.state.dados.data || "a"}
                  onChange={this.handleChange}
                  type="date"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
                <TextField
                  label="Emolumentos"
                  name="emolumentos"
                  value={this.state.emolumentos}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="FUNEPJ"
                  name="funepj"
                  value={this.state.funepj}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="FADESPES"
                  name="fadespes"
                  value={this.state.fadespes}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="FUNEMP"
                  name="funemp"
                  value={this.state.funemp}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="FUNCAD"
                  name="funcad"
                  value={this.state.funcad}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Encargos"
                  name="encargos"
                  value={this.state.encargos}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Valor de Referência"
                  name="valor_referencia"
                  value={this.state.valor_referencia}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Protocolo/Apontamento"
                  name="protocolo_apontamento"
                  value={this.state.protocolo_apontamento}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Firma"
                  name="firma"
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  disabled
                />
              </div>

              <div className="modal-footer">
                <button className="btn btn-primary" onClick={this.handleSave}>
                  <FontAwesomeIcon icon={faSave} /> Salvar
                </button>
              </div>
            </>
          )}

          {this.state.estado == "funarpen" && (
            <div style={{ margin: 10 }}>
              <TextField
                label="Nome"
                name="nome_cont"
                onChange={this.handleChange}
                value={this.state.dados.nome_cont || ""}
                type="text"
                variant="outlined" // Pode ser "filled" ou "standard"
                fullWidth // O campo ocupa toda a largura disponível
                margin="normal"
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
              />
              <TextField
                select // Ativa o modo select no TextField
                label="Nome do oficio:"
                name="nomeSer"
                value={this.state.nome_ofc}
                onChange={(event) => {
                  const { value } = event.target;
                  var find = this.state.cartorios.find(
                    (cartorio) => cartorio.id == value
                  );

                  this.setState((prevState) => ({
                    dados: {
                      ...prevState.dados,
                      ["nome_ofc"]: value,
                      //   ["cns"]: find.Cns,
                    },
                  }));
                }}
                variant="outlined"
                fullWidth
                margin="normal"
              >
                {this.state.cartorios.map((cartorio) => (
                  <MenuItem key={cartorio.id} value={cartorio.id}>
                    {cartorio.nome}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label="Selecione um documento"
                name="documento"
                value={this.state.dados.doc_emt || ""}
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
                onChange={(event) => {
                  const { value } = event.target;
                  var find = this.state.cartorios.find(
                    (cartorio) => cartorio.id == value
                  );

                  this.setState((prevState) => ({
                    dados: {
                      ...prevState.dados,
                      ["doc_emt"]: value,
                      ///   ["cns"]: find.Cns,
                    },
                  }));
                }}
                fullWidth
                size="small"
                variant="outlined"
              >
                {[
                  "CERTIDÃO",
                  "CERTIDÃO DE INTEIRO TEOR DE NASCIMENTO",
                  "CERTIDÃO DE INTEIRO TEOR DE CASAMENTO",
                  "CERTIDÃO DE INTEIRO TEOR DE ÓBITO",
                  "RECONHECIMENTO DE FIRMA POR VERDADEIRA",
                  "RECONHECIMENTO DE FIRMA POR SEMELHANÇA",
                  "CERTIDÃO DE NASCIMENTO",
                  "CERTIDÃO DE CASAMENTO",
                  "CERTIDÃO DE ÓBITO",
                  "CERTIDÃO DE NASCIMENTO – EM BREVE RELATO",
                  "CERTIDÃO DE CASAMENTO – EM BREVE RELATO",
                  "CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO",
                  "CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR",
                  "CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR",
                  "CERTIDÃO DE ÓBITO - EM INTEIRO TEOR",
                  "CERTIDÃO DE NASCIMENTO – 2ª VIA",
                  "CERTIDÃO DE CASAMENTO – 2ª VIA",
                  "CERTIDÃO DE ÓBITO – 2ª VIA",
                  "CERTIDÃO DE NASCIMENTO – EM BREVE RELATO – 2ª VIA",
                  "CERTIDÃO DE CASAMENTO – EM BREVE RELATO – 2ª VIA",
                  "CERTIDÃO DE ÓBITO – EM INTEIRO BREVE RELATO – 2ª VIA",
                  "CERTIDÃO DE NASCIMENTO – EM INTEIRO TEOR – 2ª VIA",
                  "CERTIDÃO DE CASAMENTO - EM INTEIRO TEOR – 2ª VIA",
                  "CERTIDÃO DE ÓBITO - EM INTEIRO TEOR – 2ª VIA",
                  "PROCURAÇÃO PÚBLICA",
                  "ESCRITURA PÚBLICA",
                  "DECLARAÇÃO",
                  "PROCURAÇÃO",
                  "ESCRITURA PÚBLICA DECLARATÓRIA DE MATERNIDADE",
                  "ESCRITURA PÚBLICA DECLARATÓRIA DE PATERNIDADE",
                  "ESCRITURA PÚBLICA DE DIVÓRCIO CONSENSUAL",
                  "ESCRITURA PÚBLICA DE UNIÃO ESTÁVEL",
                ].map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="data de Geração"
                name="data_ger"
                type="datetime-local"
                onChange={this.handleChange}
                value={this.state.dados.data_ger || "a"}
                variant="outlined" // Pode ser "filled" ou "standard"
                fullWidth // O campo ocupa toda a largura disponível
                margin="normal"
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
              />
              <TextField
                label="Número CPF/CNPJ"
                name="nmr_fol"
                onChange={this.handleChange}
                value={this.state.dados.nmr_fol || ""}
                type="text"
                variant="outlined" // Pode ser "filled" ou "standard"
                fullWidth // O campo ocupa toda a largura disponível
                margin="normal"
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
              />
              <TextField
                label="Valor Total"
                name="nmr_Ass"
                onChange={this.handleChange}
                value={this.state.dados.nmr_Ass || ""}
                type="text"
                variant="outlined" // Pode ser "filled" ou "standard"
                fullWidth // O campo ocupa toda a largura disponível
                margin="normal"
                InputProps={{
                  style: {
                    height: 45,
                    padding: "0 5px",
                  },
                }}
              />
              <div className="modal-footer">
                <button className="btn btn-primary" onClick={this.handleSave}>
                  <FontAwesomeIcon color="#fff" icon={faSave} /> Salvar
                </button>
              </div>
            </div>
          )}
        </StaticDialog>
      </>
    );
  }
}

export default Natalina;
