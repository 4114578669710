import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faLink,
  faCopy,
  faUpload,
  faFile,
  faPlus,
  faArrowRight,
  faSave,
  faDownload,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { QRCodeCanvas } from "qrcode.react";
import Cookies from "js-cookie";
import { apiUrl } from "../../config";
import { StaticDialog } from "react-st-modal";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
class Pastas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      newFolder: false,
      nome_pasta: "",
      import: {},
    };
  }

  componentDidMount() {
    fetch(
      `${apiUrl}/${
        window.location.pathname.split("/")[1]
      }/pasta/${window.location.pathname.split("/").pop()}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({ data, Loader: false });
          });
        }
        if (response.status == 404) {
          this.setState({ error: true, Loader: false });
        }
      })
      .catch((error) => {
        toast.warn("Erro ao carregar pastas!");
      });
  }

  AddFolder = (e, item) => {
    console.log(item);
    e.target.classList.add("load");
    fetch(`${apiUrl}/folder/cert/import/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
      body: JSON.stringify({
        item: item.id,
        ref: window.location.pathname.split("/").pop(),
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          e.target.classList.remove("load");
          response.json().then((data) => {
            toast.success("Certificado importado com sucesso!");
            console.log(item);
            this.setState((prevState) => ({
              data: {
                ...prevState.data, // Mantém os outros dados dentro de data
                certs: [...prevState.data.certs, item],
              },
            }));
          });
        }
        if (response.status == 404) {
        }
      })
      .catch((error) => {
        toast.warn("Erro ao carregar pastas!");
      });
  };

  RemoveFolder = (e, item) => {
    e.target.classList.add("load");
    fetch(`${apiUrl}/folder/cert/remove/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
      body: JSON.stringify({
        item: item.id,
        ref: window.location.pathname.split("/").pop(),
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          e.target.classList.remove("load");
          response.json().then((data) => {
            toast.success("Certificado removido com sucesso!");
            this.setState((prevState) => ({
              data: {
                ...prevState.data, // Mantém as outras propriedades de data
                certs: prevState.data.certs.filter(
                  (cert) => cert.id !== item.id
                ), // Remove o item com o ID especificado
              },
            }));
          });
        }
        if (response.status == 404) {
        }
      })
      .catch((error) => {
        toast.warn("Erro ao carregar pastas!");
      });
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }

    if (this.state.error == true) {
      return (
        <>
          {" "}
          <header class="">
            <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <button
                class="font-semibold text-xl text-gray-800 leading-tight"
                onClick={() => {
                  this.props.navigate(-1);
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Erro
              </button>
            </div>
          </header>
          <main>
            <div class="py-12">
              <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                      <strong>Erro</strong>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <p>Desculpe, Essa pasta nao foi encontrada.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }

    return (
      <>
        <header class="">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />{" "}
              {this.state.data.folder.req}
            </button>
          </div>
        </header>

        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="card">
                  <div class="card-header d-flex align-items-center justify-content-between">
                    <strong> {this.state.data.folder.fam}</strong>
                    <div>
                      <button
                        className="btn btn-success"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => {
                          e.target.classList.add("load");
                          fetch(
                            `${apiUrl}/list/${
                              window.location.pathname.split("/")[1]
                            }`,
                            {
                              method: "GET",
                              credentials: "include",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                  "Bearer " + Cookies.get("auth_token"),
                              },
                            }
                          )
                            .then((response) => {
                              e.target.classList.remove("load");
                              if (response.status == 200) {
                                response.json().then((resp) => {
                                  this.setState({
                                    import: resp.certs,
                                    Loader: false,
                                  });
                                });
                              }
                            })
                            .catch((error) => {
                              e.target.classList.remove("load");
                              toast.warn("Erro ao carregar certidões!");
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faFile} /> Adicionar Certidão
                        Existente
                      </button>
                      <Link
                        to={`/${
                          window.location.pathname.split("/")[1]
                        }/create/folder/${window.location.pathname
                          .split("/")
                          .pop()}`}
                        class="btn btn-primary"
                        onClick={() => {
                          this.setState({ newFolder: true });
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faPlus} /> Emitir Certidão
                      </Link>
                    </div>
                  </div>
                  <div class="card-body">
                    <div></div>

                    {this.state.data.certs.length > 0 ? (
                      <>
                        {window.location.pathname.split("/")[1] ==
                          "funarpen" && (
                          <>
                            <>
                              <table class="ntbl table table-sm table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Tipo de Ato</th>
                                    <th scope="col">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.data.certs.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {item.nome_cont || item.nomeCont}
                                        </td>
                                        <td>{item.doc_emt || item.docEmt}</td>
                                        <td className="">
                                          <Link
                                            to={`/${
                                              window.location.pathname.split(
                                                "/"
                                              )[1]
                                            }/preview/${item.token}`}
                                            className="btn btn-primary"
                                          >
                                            <FontAwesomeIcon
                                              icon={faAngleRight}
                                            />{" "}
                                            Acessar
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </>
                          </>
                        )}
                      </>
                    ) : (
                      <> Não Encontramos Certidões!</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <StaticDialog
          isOpen={Object.keys(this.state.import).length > 0}
          title="Certidões Existentes"
          onAfterClose={(result) => {
            this.setState({ import: {} });
          }}
        >
          <div className="modal-body" style={{ margin: 10 }}>
            <div className="mt-3 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="pesquisar..."
              />
            </div>
            {window.location.pathname.split("/")[1] == "funarpen" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Tipo de Ato</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.nomeCont}</td>
                              <td>{item.docEmt}</td>
                              <td className="">
                                <div className="btn-group">
                                  {this.state.data.certs.some(
                                    (cert) => cert.id === item.id
                                  ) ? (
                                    <>
                                      <button
                                        class="btn btn-xs btn-danger"
                                        onClick={(e) =>
                                          this.RemoveFolder(e, item)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />{" "}
                                        Remover
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        class="btn btn-xs btn-success"
                                        onClick={(e) => this.AddFolder(e, item)}
                                      >
                                        <FontAwesomeIcon icon={faDownload} />{" "}
                                        Importar
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

            {window.location.pathname.split("/")[1] == "tjsp" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Iniciais do requerente</th>
                          <th scope="col">Tipo de documento</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.iniReq}</td>
                              <td>{item.docEmt || "---"}</td>
                              <td className="">
                                <div className="btn-group">
                                  <button class="btn btn-xs btn-success">
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                    Importar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

            {window.location.pathname.split("/")[1] == "tjmg" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Tipo de Livro</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.nome_pessoa}</td>
                              <td>{item.tipo_de_livro || "---"}</td>
                              <td className="">
                                <div className="btn-group">
                                  <button class="btn btn-xs btn-success">
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                    Importar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

            {window.location.pathname.split("/")[1] == "tjgo" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Civil do ato</th>
                          <th scope="col">Tipo de documento</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.civilato}</td>
                              <td>{item.docEmt || "---"}</td>
                              <td className="">
                                <div className="btn-group">
                                  <button class="btn btn-xs btn-success">
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                    Importar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

            {window.location.pathname.split("/")[1] == "tjrs" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Chave</th>
                          <th scope="col">Tipo de documento</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.token}</td>
                              <td>{item.tipo_certidao || "---"}</td>
                              <td className="">
                                <div className="btn-group">
                                  <button class="btn btn-xs btn-success">
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                    Importar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

            {window.location.pathname.split("/")[1] == "tjsc" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Nome na Certidão</th>
                          <th scope="col">Tipo de documento</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.titular}</td>
                              <td>{item.tipo_ato || "---"}</td>
                              <td className="">
                                <div className="btn-group">
                                  <button class="btn btn-xs btn-success">
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                    Importar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

            {window.location.pathname.split("/")[1] == "tjes" && (
              <>
                {this.state.import.length > 0 && (
                  <>
                    <table class="ntbl table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Ato</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.import.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.nome}</td>
                              <td>{item.ato || "---"}</td>
                              <td className="">
                                <div className="btn-group">
                                  <button class="btn btn-xs btn-success">
                                    <FontAwesomeIcon icon={faDownload} />{" "}
                                    Importar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.newFolder}
          title="Criar Pasta"
          onAfterClose={(result) => {
            this.setState({ newFolder: false, nome_pasta: "" });
          }}
        >
          <div className="modal-body" style={{ margin: 10 }}>
            <TextField
              label="Nome da Pasta"
              name="nome_pasta"
              onChange={(e) => {
                this.setState({ nome_pasta: e.target.value });
              }}
              value={this.state.nome_pasta || ""}
              variant="outlined" // Pode ser "filled" ou "standard"
              fullWidth // O campo ocupa toda a largura disponível
              margin="normal"
              InputProps={{
                style: {
                  height: 45,
                  padding: "0 5px",
                },
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                var nome_pasta = this.state.nome_pasta;
                if (nome_pasta.trim().length == 0) {
                  toast.warn("Preencha o Nome da Pasta!");
                  return;
                }
                fetch(
                  `${apiUrl}/${
                    window.location.pathname.split("/")[1]
                  }/pastas/adicionar`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({
                      nome_pasta: this.state.nome_pasta,
                    }),
                  }
                )
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState({ data });
                        this.props.navigate(-1);
                      });
                    }
                  })
                  .catch((error) => {
                    toast.warn("Erro ao criar pasta!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
        <ToastContainer />
      </>
    );
  }
}

export default Pastas;
